import React, { useContext } from 'react'
import Plx from 'react-plx'
import parallaxData from '../util/parallaxData'
import GlobalContext from '../context/GlobalContext'
import viewData from '../util/viewData'
import CartContext from '../context/CartContext'
import { useHistory } from 'react-router'
import ProductInfo from './ProductInfo'
import { deviceType } from 'react-device-detect'
import ReactGA from 'react-ga'

const PageView = props => {
	const { menuOpen, openMenu, cartData } = useContext(GlobalContext)
	const {
		name,
		title,
		subTitle,
		images,
		product,
		content,
		margin,
		padding,
		logo,
		orientation,
		next
	} = viewData.filter(d => (props.path === d.name))[0]
	const delay = 888
	const { bottles, setBottles, boxes, setBoxes } = useContext(CartContext)
	const history = useHistory()
	const mMargin = deviceType !== 'tablet' ? margin : margin / 2

	return (
		<section id={"page-" + name}>


			{images && images[0] ?
				<figure className="fast animated fadeIn" style={{
					animationDelay: '.0s',
					paddingLeft: !props.isMobile && padding ? padding : null,
					flexBasis: logo ? 'calc(var(--flex) + ' + props.logoWidth / 2 + 'px)' : null,
					backgroundImage: props.isMobile ? "url('" + process.env.REACT_APP_URL + images[0] + "')" : null,
				}}>
					<img src={process.env.REACT_APP_URL + images[0]} alt="" style={{
						objectPosition: !props.isMobile && orientation === 'RIGHT' ? 'left center' : null
					}} />

					{props.isMobile ?
						<>
							<Plx parallaxData={parallaxData.shade} className="shade" />
							<Plx parallaxData={parallaxData.title}>
								<h1 style={{ animationDelay: '.222s' }}>
									{title}
								</h1>
							</Plx>
							{/* <Plx parallaxData={parallaxData.subtitle}>
								{product?
									<h2 style={{ animationDelay: '.666s' }}>
										{subTitle}
									</h2>
								:null}
							</Plx> */}
						</>
						: null}

				</figure>

				: null}


			{props.isMobile ? <h1 className="logo" onClick={() => openMenu(!menuOpen)}>LVIVSKA</h1> : null}


			<article className={(orientation === 'RIGHT') ? 'align-right' : null} style={{
				marginBottom: margin ? '-' + mMargin + '%' : null,
				//marginBottom: margin < 0 ? mMargin * -1 + '%' : null,
				marginTop: margin < 0 ? mMargin + '%' : null,
			}}>
				{product && !props.isMobile ? <button className="light ghost next" onClick={() => history.push('/' + next)}>nächstes Produkt <span>&rarr;</span></button> : null}

				<Plx parallaxData={parallaxData.bodyTitle}>
					<h1 className="slow animated fadeIn" style={{ animationDelay: '.222s' }}>
						{title}
					</h1>
				</Plx>
				<Plx parallaxData={parallaxData.content}>

					{!product
						? <p className="slow animated fadeIn" style={{ animationDelay: '.666s' }}>
							{subTitle}
						</p>
						: <>
							<h2 className="slow animated fadeIn" style={{ animationDelay: '.666s' }}>
								{subTitle}
							</h2>
							<ProductInfo product={product} cartData={cartData} isMobile={props.isMobile} />
						</>}

					{content.map((row, i) => {
						return <p key={'content-' + i} className="slow animated fadeIn" style={{ animationDelay: (222 * i + delay) / 1000 + 's' }}>
							{row}
						</p>
					})}

					{props.isMobile && product ?
						<p className="slow animated fadeIn" style={{ animationDelay: '1.332s' }}>
							<img src={process.env.REACT_APP_URL + images[1]} alt="" />
						</p>
						: null}

					{product ?
						product === 'bottle'
							? <button className="primary slow animated fadeIn product" onClick={() => {
								setBottles(bottles + 1)
								ReactGA.event({
									category: 'Kunde',
									action: 'in den Warenkorb gelegt',
									label: name
								})
							}} style={{ animationDelay: (222 * content.length + delay) / 1000 + 's' }}>
								{bottles ? '+1 ' : null}
								in den Warenkorb
								{/* <span>&#8627;</span> */}
							</button>
							: <button className="primary slow animated fadeIn product" onClick={() => {
								setBoxes(boxes + 1)
								ReactGA.event({
									category: 'Kunde',
									action: 'in den Warenkorb gelegt',
									label: name
								})
							}} style={{ animationDelay: (222 * content.length + delay) / 1000 + 's' }}>
								{boxes ? '+1 ' : null}
								in den Warenkorb
								{/* <span>&#8627;</span> */}
							</button>
						: null}

				</Plx>

			</article>


			{props.isMobile && next ?
				<Plx parallaxData={parallaxData.nextBtn} className="next">
					<button
						className="light"
						onClick={() => history.push('/' + next)}
					>
						{!product ? 'nächste Seite' : 'nächstes Produkt'}
					</button>
				</Plx>
				: null}


		</section>
	)
}

export default PageView