const headers = new Headers()
//headers.set('Authorization', 'Basic ' + btoa(process.env.REACT_APP_PP_CLIENT + ":" + process.env.REACT_APP_PP_SECRET))


const createShipment = args => {
	if (!args || !Object.keys(args).length) return false

	const action = args.action ? '/?action=' + args.action : null
	const body = new FormData()
	for (const param in args)
		if (param !== 'cb' && param !== 'action')
			body.append(param, args[param])


	fetch( process.env.REACT_APP_LBL_URL + action, {
		method: 'POST',
		headers,
		body
	})
		.then(response => response.text())
		.then(result => {
			
			// parse XML content string to XML DOM object
			const parser = new DOMParser()
			const xmlDoc = parser.parseFromString(result, 'text/xml')
			//console.log(result)

			// access to XML nodes and get node values
			const status = xmlDoc.getElementsByTagName('statusText')[0].textContent// 'ok' || 'Type.'
			const message = xmlDoc.getElementsByTagName('statusMessage')[0].textContent
			const labelURL = xmlDoc.getElementsByTagName('labelUrl').length
				? xmlDoc.getElementsByTagName('labelUrl')[0].textContent
				: false
			const shipment = xmlDoc.getElementsByTagName('shipmentNumber').length
				? xmlDoc.getElementsByTagName('shipmentNumber')[0].textContent
				: false

			if (args.cb) args.cb(status, message, labelURL, shipment)

		})
		.catch(err => console.error(err))
}

export default createShipment


export const getLabel = args => {
	//const { date, weight, dimensions, email, fullName, street, number, zip, city, state } = args ? args : {}

	fetch( process.env.REACT_APP_LBL_URL + '/?action=view', {
		method: 'POST',
		headers,
	})
		.then(response => response.text())
		.then(result => {
			// parse XML content string to XML DOM object
			var parser = new DOMParser()
			var xmlDoc = parser.parseFromString(result, 'text/xml')

			// access to XML nodes and get node values
			var status = xmlDoc.getElementsByTagName('statusText')[0].textContent
			var message = xmlDoc.getElementsByTagName('statusMessage')[0].textContent
			console.log(status, message)
		})
		.catch(err => console.error(err))
}

