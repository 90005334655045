import React from 'react'

const widerruf = [
	<h1>Widerrufsbelehrung</h1>,
	<>
		<br />
		Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können.
		<br />
		<br />
		<br />
	</>,
	<h2>Widerrufsrecht</h2>,
	<>
		<br />

		Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die Waren in Besitz genommen haben bzw. hat. Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Firma frame. TV- und Filmproduktion GmbH, Nelkenstraße 15, 50733 Köln+49 (0) 2196 888 224 +49 (0) 2196 888 223, E-Mail-Adresse: widerruf@shop.lvivska.de) mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist. Sie können das Muster-Widerrufsformular oder eine andere eindeutige Erklärung auch auf unserer Webseite  <a href="https://www.lvivska.de/LVIVSKA_Shop-Widerrufsformular.pdf" target="_blank" class="external" rel="noopener noreferrer">https://www.lvivska.de/LVIVSKA_Shop-Widerrufsformular.pdf</a> elektronisch ausfüllen und übermitteln. Machen Sie von dieser Möglichkeit Gebrauch, so werden wir Ihnen unverzüglich (z.B. per E-Mail) eine Bestätigung über den Eingang eines solchen Widerrufs übermitteln.  Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
		<br />
		<br />
		<br />
	</>,
	<h2>Folgen des Widerrufs</h2>,
	<>
		<br />

		Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet. Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist. Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an Eastern Spirits, Neuenflügel 39a, 42929 Wermelskirchen zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden. Sie tragen die unmittelbaren Kosten der Rücksendung der Waren. Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zurückzuführen ist.
		<br />
		<br />
		<br />
	</>,
	<h2>Ausschluss bzw. vorzeitiges Erlöschen des Widerrufsrechts</h2>,
	<>
		<br />Das Widerrufsrecht besteht nicht bei Verträgen<br />
		<br />

		- zur Lieferung von Waren, die nicht vorgefertigt sind und für deren Herstellung eine individuelle Auswahl oder Bestimmung durch den Verbraucher maßgeblich ist oder die eindeutig auf die persönlichen Bedürfnisse des Verbrauchers zugeschnitten sind;<br />
		- zur Lieferung von Waren, die schnell verderben können oder deren Verfallsdatum schnell überschritten würde;<br />
		- zur Lieferung alkoholischer Getränke, deren Preis bei Vertragsschluss vereinbart wurde, die aber frühestens 30 Tage nach Vertragsschluss geliefert werden können und deren aktueller Wert von Schwankungen auf dem Markt abhängt, auf die der Unternehmer keinen Einfluss hat;<br />
		- zur Lieferung von Zeitungen, Zeitschriften oder Illustrierten mit Ausnahme von Abonnement-Verträgen.
		<br />
		<br />

		Das Widerrufsrecht erlischt vorzeitig bei Verträgen<br />
		<br />

		- zur Lieferung versiegelter Waren, die aus Gründen des Gesundheitsschutzes oder der Hygiene nicht zur Rückgabe geeignet sind, wenn ihre Versiegelung nach der Lieferung entfernt wurde;<br />
		- zur Lieferung von Waren, wenn diese nach der Lieferung auf Grund ihrer Beschaffenheit untrennbar mit anderen Gütern vermischt wurden;<br />
		- zur Lieferung von Ton- oder Videoaufnahmen oder Computersoftware in einer versiegelten Packung, wenn die Versiegelung nach der Lieferung entfernt wurde.
		<br />
		<br />
	</>,
	<h2>Muster-Widerrufsformular</h2>,
	<>
		<br />
		<p>(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)</p
		>An<br />
		Firma<br />
		frame. TV- und Filmproduktion GmbH<br />
		Nelkenstraße 15<br />
		50733 Köln<br />
		+49 (0) 2196 888 223<br />
		E-Mail-Adresse: widerruf@shop.lvivska.de
		<br />
		<br />

		<br />
		- Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)<br />
		<br />
		- Bestellt am (*)/erhalten am (*)<br />
		<br />
		- Name des/der Verbraucher(s)<br />
		<br />
		- Anschrift des/der Verbraucher(s)<br />
		<br />
		- Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier) <br />
		<br />
		- Datum<br />
		---------------------------------------<br />
		(*) Unzutreffendes streichen.
	</>,
	<br />,
	<em>
		Quelle: <a href="https://www.ra-plutte.de/widerrufsbelehrung-generator/" target="_blank" alt="Widerrufsbelehrung Generator" rel="noopener noreferrer">www.ra-plutte.de/widerrufsbelehrung-generator/</a>
	</em>,
	<br />,
]

export default widerruf