import React from 'react'

const GlobalContext = React.createContext({
	currentPage: '',
	setCurrentPage: () => {},
	menuOpen: false,
	openMenu: () => {},
	cartData: () => {},
})

export default GlobalContext