

export const apiRequest = args => {

	/*
	 *	0.1.	Sendung validieren
	 *	– dhlRequest
	*/
	const payment = args.data

	/*
	 *	1.	Rechnung
	 *	– Rechnungsnummer /v2/invoicing/generate-next-invoice-number √
	 *	– Entwurf /v2/invoicing/invoices
	 *	– Senden /v2/invoicing/invoices/{invoice_id}/send
	*/
	const headers = new Headers()
	headers.set('Authorization', 'Basic ' + btoa(process.env.REACT_APP_PP_CLIENT + ":" + process.env.REACT_APP_PP_SECRET))
	const body = new FormData()
	body.append('grant_type', 'client_credentials')

	fetch(process.env.REACT_APP_PP_API + '/v1/oauth2/token', {
		method: 'POST',
		headers,
		body: 'grant_type=client_credentials'
	})
		.then(response => response.json())
		.then(res => {
			const token = res.access_token
			const headers = new Headers()
			headers.set('Authorization', 'Bearer ' + token)
			headers.set('Content-Type', 'application/json')
			fetch(process.env.REACT_APP_PP_API + '/v2/checkout/orders/' + payment.orderID, {
				method: 'GET',
				headers,
			})
				.then(response => response.json())
				.then(res => {
					//console.log(res)
					const { create_time, id, payer } = res // eslint-disable-line
					const purchase_units = res.purchase_units[0]
							const stencil = {// eslint-disable-line
								detail: {
									invoice_number: "#123",
									reference: "deal-ref",
									invoice_date: "2018-11-12",
									currency_code: "USD",
									note: "Thank you for your business.",
									term: "No refunds after 30 days.",
									memo: "This is a long contract",
									payment_term: {
										due_date: "2020-11-22"
									}
								},
								invoicer: {
									name: {
										given_name: "David",
										surname: "Larusso"
									},
									address: {
										address_line_1: "1234 First Street",
										address_line_2: "337673 Hillside Court",
										admin_area_2: "Anytown",
										admin_area_1: "CA",
										postal_code: "98765",
										country_code: "US"
									},
									email_address: "sb-dqmjf1543823@business.example.com",
									phones: [
										{
											country_code: "001",
											national_number: "4085551234",
											phone_type: "MOBILE"
										}
									],
									website: "www.test.com",
									tax_id: "ABcNkWSfb5ICTt73nD3QON1fnnpgNKBy- Jb5SeuGj185MNNw6g",
									logo_url: "https://example.com/logo.PNG",
									additional_notes: "2-4"
								},
								primary_recipients: [
									{
										billing_info: {
											name: {
												given_name: "Stephanie",
												surname: "Meyers"
											},
											address: {
												address_line_1: "1234 Main Street",
												admin_area_2: "Anytown",
												admin_area_1: "CA",
												postal_code: "98765",
												country_code: "US"
											},
											email_address: "bill-me@example.com",
											phones: [
												{
													country_code: "001",
													national_number: "4884551234",
													phone_type: "HOME"
												}
											],
											additional_info_value: "add-info"
										},
										shipping_info: {
											name: {
												given_name: "Stephanie",
												surname: "Meyers"
											},
											address: {
												address_line_1: "1234 Main Street",
												admin_area_2: "Anytown",
												admin_area_1: "CA",
												postal_code: "98765",
												country_code: "US"
											}
										}
									}
								],
								items: [
									{
										name: "Yoga Mat",
										description: "Elastic mat to practice yoga.",
										quantity: "1",
										unit_amount: {
											currency_code: "USD",
											value: "50.00"
										},
										tax: {
											name: "Sales Tax",
											percent: "7.25"
										},
										discount: {
											percent: "5"
										},
										unit_of_measure: "QUANTITY"
									},
									{
										name: "Yoga t-shirt",
										quantity: "1",
										unit_amount: {
											currency_code: "USD",
											value: "10.00"
										},
										tax: {
											name: "Sales Tax",
											percent: "7.25"
										},
										discount: {
											amount: {
												currency_code: "USD",
												value: "5.00"
											}
										},
										unit_of_measure: "QUANTITY"
									}
								],
								configuration: {
									partial_payment: {
										allow_partial_payment: true,
										minimum_amount_due: {
											currency_code: "USD",
											value: "20.00"
										}
									},
									allow_tip: true,
									tax_calculated_after_discount: true,
									tax_inclusive: false,
									template_id: "TEMP-19V05281TU309413B"
								},
								amount: {
									breakdown: {
										custom: {
											label: "Packing Charges",
											amount: {
												currency_code: "USD",
												value: "10.00"
											}
										},
										shipping: {
											amount: {
												currency_code: "USD",
												value: "10.00"
											},
											tax: {
												name: "Sales Tax",
												percent: "7.25"
											}
										},
										discount: {
											invoice_discount: {
												percent: "5"
											}
										}
									}
								}
							}
							const body = {
								detail: {
									//invoice_number: '#' + Math.round(100000 * Math.random()),
									invoice_date: create_time,
									currency_code: purchase_units.amount.currency_code,
									payment_term: {
										term_type: "NET_15",
									},
									shipping: purchase_units.shipping
								},
								invoicer: {
									name: {
										given_name: "Jürgen",
										surname: "Lindemann",
										full_name: "Jürgen Lindemann"
									},
									address: {
										address_line_1: "Nelkenstraße 15",
										address_line_2: "Köln",
										//admin_area_2: "Anytown",
										//admin_area_1: "CA",
										postal_code: "50733",
										country_code: "DE"
									},
									website: "www.eastern-spirits.com",
									tax_id: "DE275975083",
									logo_url: "https://example.com/logo.PNG",
									additional_notes: "Vielen Dank für Ihren Einkauf.",
									business_name: 'frame. TV- und Filmrpoduktion GmbH',
									email_address: purchase_units.payee.email_address
									//email_address: 'shop@lvivska.de'
								},
								items: purchase_units.items,
								amount: purchase_units.amount,
								primary_recipients: [
									{...payer}
								],
							}
							if (args.cb) args.cb(body)
				})
		})

	/*
	 *	1.	Lieferschein
	 *	– Erstellen
	 *	– Senden
	*/

}

