import React, { useState, useEffect } from 'react'
import { Transition } from 'react-transition-group'
import { getCookie, setCookie } from '../util/cookies'
import { isMobile } from 'react-device-detect'

const AgeVerify = props => {
	const [ageInput, setAgeInput] = useState('')
	const [intro, setIntro] = useState(false)
	const calcAge = dateString => {
		const birthday = +new Date(dateString);
		return ~~((Date.now() - birthday) / (31557600000));
	}
	const hasCookie = getCookie('ageIsVerified')

	useEffect(() => {
		if (!ageInput.match(/[1-2][0-9][0-9][0-9]-[0-1][0-9]-[0-3][0-9]/) || calcAge(ageInput) <= 18)
			return () => { }

		setCookie('ageIsVerified', true, 2)
		props.setAgeVerified(true)

	}, [ageInput, props])

	useEffect(() => {
		const to = setTimeout(() => setIntro(true), 666)
		return () => clearTimeout(to)
	}, [])

	useEffect(() => {
		if (intro && hasCookie) {
			props.setHasCookie(true)
			props.setAgeVerified(true)
		}
	}, [intro, props, hasCookie])

	return (
		<aside id="age-verification">
			<Transition in={intro} timeout={666}>
				{state =>
					<>
						<h1
							data-state={state}
							className="logo"
							style={{
								transform: !intro 
									? isMobile
										? 'scale(2)'
										: 'scale(4)'
									: 'scale(1)'
							}}
						>LVIVSKA</h1>
						{!hasCookie?
							<>
								<h2
									className="slow animated fadeIn"
									style={{
										overflowY: 'hidden',
										maxHeight: state === 'entered' ? '2rem' : '0rem',
										transformOrigin: 'top',
										transform: state === 'entered' ? 'scaleY(1)' : 'scaleY(0)',
									}}
								>
									Alters&shy;bestätigung
								</h2>
								<p
									className="input slow animated fadeIn"
									style={{
										overflowY: 'hidden',
										maxHeight: state === 'entered' ? '6.4rem' : '0rem',
										transformOrigin: 'top',
										transform: state === 'entered' ? 'scaleY(1)' : 'scaleY(0)',
									}}
								>
									<label htmlFor="birthday">Geburtsdatum:</label>
									<input type="date" name="birthday" placeholder="YYYY-MM-DD" value={ageInput} onChange={e => setAgeInput(e.target.value)} />
									<input type="date" name="birthday-size" placeholder="YYYY-MM-DD" value={ageInput} disabled />
								</p>
							</>
						:null}
					</>
				}
			</Transition>
		</aside>
	)
}

export default AgeVerify