import React from 'react'
import viewData from '../util/viewData'

const ProductInfo = props => {
	const { product, cartData } = props
	const { weight, B, H, T } = viewData.filter(p => p.name === 'products/' + product)[0].details

	return (
		<aside className="info animated fadeIn slow" style={{ animationDelay: '1.554s' }}>
			{!cartData().discount
				? <p>
					<span>{cartData().price[product].toFixed(2)} €</span><br />
					<small>{(cartData().price[product] / 7 * 10).toFixed(2)} € / l</small><br />
					<small>inkl. {cartData().price['mwst']} % MwSt. {/* zzgl. Versandkosten */}</small>
				</p>
				:
				<p>
					<del>{(cartData().price[product] / (100 - cartData().discount) * 100).toFixed(2)} €</del><br />
					<span>{cartData().price[product].toFixed(2)} €</span><br />
					<small>{(cartData().price[product] / 7 * 10).toFixed(2)} € / l</small><br />
					<small>inkl. {cartData().price['mwst']} % MwSt. {/* zzgl. Versandkosten */}</small>
				</p>
			}
			<p>
				<span className={cartData().stock[product] > 50 ? null : 'primary'}>{cartData().stock[product]} vorrätig</span><br />
				<small>5 bis 10 Werktage<br />Lieferzeit</small>
			</p>
			<div className="details">
				{!props.isMobile ? <span>Gewicht:</span> : null}	<span>{weight} kg</span><br />
				{!props.isMobile ? <span>Größe:</span> : null}		<span>{B} × {H} × {T} cm</span>
			</div>
		</aside>
	)
}

export default ProductInfo