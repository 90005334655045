import React from 'react'

const agb = [
	<h1 class="itkanzlei_first_headline">Allgemeine Geschäftsbedingungen mit Kundeninformationen</h1>,
	<h2 id="Inhaltsverzeichnis">Inhaltsverzeichnis</h2>,
	<ol>
		<li><a href="#1">Geltungsbereich</a></li>
		<li><a href="#2">Vertragsschluss</a></li>
		<li><a href="#3">Widerrufsrecht</a></li>
		<li><a href="#4">Preise und Zahlungsbedingungen</a></li>
		<li><a href="#5">Liefer- und Versandbedingungen</a></li>
		<li><a href="#6">Eigentumsvorbehalt</a></li>
		<li><a href="#7">Mängelhaftung (Gewährleistung)</a></li>
		<li><a href="#8">Einlösung von Aktionsgutscheinen</a></li>
		<li><a href="#9">Einlösung von Geschenkgutscheinen</a></li>
		<li><a href="#10">Anwendbares Recht</a></li>
		<li><a href="#11">Gerichtsstand</a></li>
		<li><a href="#12">Alternative Streitbeilegung</a></li>
	</ol>,
	<h2 id="1">1) Geltungsbereich</h2>,
	<>
		<strong id="1-1">1.1</strong><br />
		Diese Allgemeinen Geschäftsbedingungen (nachfolgend "AGB") der frame. TV- und Filmrpoduktion GmbH (nachfolgend "Verkäufer"), gelten für alle Verträge über die Lieferung von Waren, die ein Verbraucher oder Unternehmer (nachfolgend „Kunde“) mit dem Verkäufer hinsichtlich der vom Verkäufer in seinem Online-Shop dargestellten Waren abschließt. Hiermit wird der Einbeziehung von eigenen Bedingungen des Kunden widersprochen, es sei denn, es ist etwas anderes vereinbart.
	</>,
	<>
		<strong id="1-2">1.2</strong><br />
		Für Verträge über die Lieferung von Gutscheinen gelten diese AGB entsprechend, sofern insoweit nicht ausdrücklich etwas Abweichendes geregelt ist.
	</>,
	<>
		<strong id="1-3">1.3</strong><br />
		Verbraucher im Sinne dieser AGB ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können. Unternehmer im Sinne dieser AGB ist eine natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt.
	</>,
	<h2 id="2">2) Vertragsschluss</h2>,
	<>
		<strong id="2-1">2.1</strong><br />
		Die im Online-Shop des Verkäufers enthaltenen Produktbeschreibungen stellen keine verbindlichen Angebote seitens des Verkäufers dar, sondern dienen zur Abgabe eines verbindlichen Angebots durch den Kunden.<br />
		<br />
		<strong id="2-2">2.2</strong><br />
		Der Kunde kann das Angebot über das in den Online-Shop des Verkäufers integrierte Online-Bestellformular abgeben. Dabei gibt der Kunde, nachdem er die ausgewählten Waren in den virtuellen Warenkorb gelegt und den elektronischen Bestellprozess durchlaufen hat, durch Klicken des den Bestellvorgang abschließenden Buttons ein rechtlich verbindliches Vertragsangebot in Bezug auf die im Warenkorb enthaltenen Waren ab. Ferner kann der Kunde das Angebot auch telefonisch, per Fax, per E-Mail, postalisch oder per Online-Kontaktformular gegenüber dem Verkäufer abgeben.<br />
		<br />
		<strong id="2-3">2.3</strong><br />
		Der Verkäufer kann das Angebot des Kunden innerhalb von fünf Tagen annehmen,
		<ul>
			<li>indem er dem Kunden eine schriftliche Auftragsbestätigung oder eine Auftragsbestätigung in Textform (Fax oder E-Mail) übermittelt, wobei insoweit der Zugang der Auftragsbestätigung beim Kunden maßgeblich ist, oder</li>
			<li>indem er dem Kunden die bestellte Ware liefert, wobei insoweit der Zugang der Ware beim Kunden maßgeblich ist, oder</li>
			<li>indem er den Kunden nach Abgabe von dessen Bestellung zur Zahlung auffordert.</li>
		</ul>
		Liegen mehrere der vorgenannten Alternativen vor, kommt der Vertrag in dem Zeitpunkt zustande, in dem eine der vorgenannten Alternativen zuerst eintritt. Die Frist zur Annahme des Angebots beginnt am Tag nach der Absendung des Angebots durch den Kunden zu laufen und endet mit dem Ablauf des fünften Tages, welcher auf die Absendung des Angebots folgt. Nimmt der Verkäufer das Angebot des Kunden innerhalb vorgenannter Frist nicht an, so gilt dies als Ablehnung des Angebots mit der Folge, dass der Kunde nicht mehr an seine Willenserklärung gebunden ist.<br />
		<br />
		<strong id="2-4">2.4</strong><br />
		Bei der Abgabe eines Angebots über das Online-Bestellformular des Verkäufers wird der Vertragstext nach dem Vertragsschluss vom Verkäufer gespeichert und dem Kunden nach Absendung von dessen Bestellung in Textform (z. B. E-Mail, Fax oder Brief) übermittelt. Eine darüber hinausgehende Zugänglichmachung des Vertragstextes durch den Verkäufer erfolgt nicht. Sofern der Kunde vor Absendung seiner Bestellung ein Nutzerkonto im Online-Shop des Verkäufers eingerichtet hat, werden die Bestelldaten auf der Website des Verkäufers archiviert und können vom Kunden über dessen passwortgeschütztes Nutzerkonto unter Angabe der entsprechenden Login-Daten kostenlos abgerufen werden.<br />
		<br />
		<strong id="2-5">2.5</strong><br />
		Vor verbindlicher Abgabe der Bestellung über das Online-Bestellformular des Verkäufers kann der Kunde mögliche Eingabefehler durch aufmerksames Lesen der auf dem Bildschirm dargestellten Informationen erkennen. Ein wirksames technisches Mittel zur besseren Erkennung von Eingabefehlern kann dabei die Vergrößerungsfunktion des Browsers sein, mit deren Hilfe die Darstellung auf dem Bildschirm vergrößert wird. Seine Eingaben kann der Kunde im Rahmen des elektronischen Bestellprozesses so lange über die üblichen Tastatur- und Mausfunktionen korrigieren, bis er den den Bestellvorgang abschließenden Button anklickt.<br />
		<br />
		<strong id="2-6">2.6</strong><br />
		Für den Vertragsschluss steht ausschließlich die deutsche Sprache zur Verfügung.<br />
		<br />
		<strong id="2-7">2.7</strong><br />
		Die Bestellabwicklung und Kontaktaufnahme finden in der Regel per E-Mail und automatisierter Bestellabwicklung statt. Der Kunde hat sicherzustellen, dass die von ihm zur Bestellabwicklung angegebene E-Mail-Adresse zutreffend ist, so dass unter dieser Adresse die vom Verkäufer versandten E-Mails empfangen werden können. Insbesondere hat der Kunde bei dem Einsatz von SPAM-Filtern sicherzustellen, dass alle vom Verkäufer oder von diesem mit der Bestellabwicklung beauftragten Dritten versandten E-Mails zugestellt werden können.<br />
		<br />
		<strong id="2-8">2.8</strong><br />
		Bei einer Bestellung von alkoholischen Getränken bestätigt der Kunde mit Absenden der Bestellung, dass er das gesetzlich erforderliche Mindestalter erreicht hat.
	</>,
	<h2 id="3">3) Widerrufsrecht</h2>,
	<>
		<strong id="3-1">3.1</strong><br />
		Verbrauchern steht grundsätzlich ein Widerrufsrecht zu.<br />
		<br />
		<strong id="3-2">3.2</strong><br />
		Nähere Informationen zum Widerrufsrecht ergeben sich aus der Widerrufsbelehrung des Verkäufers.<br />
		<br />
		<strong id="3-3">3.3</strong><br />
		Das Widerrufsrecht gilt nicht für Verbraucher, die zum Zeitpunkt des Vertragsschlusses keinem Mitgliedstaat der Europäischen Union angehören und deren alleiniger Wohnsitz und Lieferadresse zum Zeitpunkt des Vertragsschlusses außerhalb der Europäischen Union liegen.
	</>,
	<h2 id="4">4) Preise und Zahlungsbedingungen</h2>,
	<>
		<strong id="4-1">4.1</strong><br />
		Sofern sich aus der Produktbeschreibung des Verkäufers nichts anderes ergibt, handelt es sich bei den angegebenen Preisen um Gesamtpreise, die die gesetzliche Umsatzsteuer enthalten. Gegebenenfalls zusätzlich anfallende Liefer- und Versandkosten werden in der jeweiligen Produktbeschreibung gesondert angegeben.<br />
		<br />
		<strong id="4-2">4.2</strong><br />
		Bei Lieferungen in Länder außerhalb der Europäischen Union können im Einzelfall weitere Kosten anfallen, die der Verkäufer nicht zu vertreten hat und die vom Kunden zu tragen sind. Hierzu zählen beispielsweise Kosten für die Geldübermittlung durch Kreditinstitute (z.B. Überweisungsgebühren, Wechselkursgebühren) oder einfuhrrechtliche Abgaben bzw. Steuern (z.B. Zölle). Solche Kosten können in Bezug auf die Geldübermittlung auch dann anfallen, wenn die Lieferung nicht in ein Land außerhalb der Europäischen Union erfolgt, der Kunde die Zahlung aber von einem Land außerhalb der Europäischen Union aus vornimmt.<br />
		<br />
		<strong id="4-3">4.3</strong><br />
		Die Zahlungsmöglichkeit/en wird/werden dem Kunden im Online-Shop des Verkäufers mitgeteilt.<br />
		<br />
		<strong id="4-4">4.4</strong><br />
		Ist Vorauskasse per Banküberweisung vereinbart, ist die Zahlung sofort nach Vertragsabschluss fällig, sofern die Parteien keinen späteren Fälligkeitstermin vereinbart haben.<br />
		<br />
		<strong id="4-5">4.5</strong><br />
		Bei Zahlung mittels einer von PayPal angebotenen Zahlungsart erfolgt die Zahlungsabwicklung über den Zahlungsdienstleister PayPal (Europe) S.à r.l. et Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg (im Folgenden: "PayPal"), unter Geltung der PayPal-Nutzungsbedingungen, einsehbar unter https://www.paypal.com/de/webapps/mpp/ua/useragreement-full oder - falls der Kunde nicht über ein PayPal-Konto verfügt – unter Geltung der Bedingungen für Zahlungen ohne PayPal-Konto, einsehbar unter https://www.paypal.com/de/webapps/mpp/ua/privacywax-full.<br />
		<br />
		<strong id="4-6">4.6</strong><br />
		Bei Auswahl der Zahlungsart „SOFORT“ erfolgt die Zahlungsabwicklung über den Zahlungsdienstleister SOFORT GmbH, Theresienhöhe 12, 80339 München (im Folgenden „SOFORT“). Um den Rechnungsbetrag über „SOFORT“ bezahlen zu können, muss der Kunde über ein für die Teilnahme an „SOFORT“ frei geschaltetes Online-Banking-Konto verfügen, sich beim Zahlungsvorgang entsprechend legitimieren und die Zahlungsanweisung gegenüber „SOFORT“ bestätigen. Die Zahlungstransaktion wird unmittelbar danach von „SOFORT“ durchgeführt und das Bankkonto des Kunden belastet. Nähere Informationen zur Zahlungsart „SOFORT“ kann der Kunde im Internet unter https://www.klarna.com/sofort/ abrufen.<br />
		<br />
		<strong id="4-7">4.7</strong><br />
		Bei Auswahl der Zahlungsart Rechnungskauf wird der Kaufpreis fällig, nachdem die Ware geliefert und in Rechnung gestellt wurde. In diesem Fall ist der Kaufpreis innerhalb von 14 (vierzehn) Tagen ab Erhalt der Rechnung ohne Abzug zu zahlen, sofern nichts anderes vereinbart ist. Der Verkäufer behält sich vor, die Zahlungsart Rechnungskauf nur bis zu einem bestimmten Bestellvolumen anzubieten und diese Zahlungsart bei Überschreitung des angegebenen Bestellvolumens abzulehnen. In diesem Fall wird der Verkäufer den Kunden in seinen Zahlungsinformationen im Online-Shop auf eine entsprechende Zahlungsbeschränkung hinweisen. Der Verkäufer behält sich ferner vor, bei Auswahl der Zahlungsart Rechnungskauf eine Bonitätsprüfung durchzuführen und diese Zahlungsart bei negativer Bonitätsprüfung abzulehnen.<br />
		<br />
		<strong id="4-8">4.8</strong><br />
		Bei Auswahl der Zahlungsart SEPA-Lastschrift ist der Rechnungsbetrag nach Erteilung eines SEPA-Lastschriftmandats, nicht jedoch vor Ablauf der Frist für die Vorabinformation zur Zahlung fällig. Der Einzug der Lastschrift erfolgt, wenn die bestellte Ware das Lager des Verkäufers verlässt, nicht jedoch vor Ablauf der Frist für die Vorabinformation. Vorabinformation ("Pre-Notification") ist jede Mitteilung (z.B. Rechnung, Police, Vertrag) des Verkäufers an den Kunden, die eine Belastung mittels SEPA-Lastschrift ankündigt. Wird die Lastschrift mangels ausreichender Kontodeckung oder aufgrund der Angabe einer falschen Bankverbindung nicht eingelöst oder widerspricht der Kunde der Abbuchung, obwohl er hierzu nicht berechtigt ist, hat der Kunde die durch die Rückbuchung des jeweiligen Kreditinstituts entstehenden Gebühren zu tragen, wenn er dies zu vertreten hat. Der Verkäufer behält sich vor, bei Auswahl der Zahlungsart SEPA-Lastschrift eine Bonitätsprüfung durchzuführen und diese Zahlungsart bei negativer Bonitätsprüfung abzulehnen.
	</>,
	<h2 id="5">5) Liefer- und Versandbedingungen</h2>,
	<>
		<strong id="5-1">5.1</strong><br />
		Die Lieferung von Waren erfolgt auf dem Versandweg an die vom Kunden angegebene Lieferanschrift, sofern nichts anderes vereinbart ist. Bei der Abwicklung der Transaktion ist die in der Bestellabwicklung des Verkäufers angegebene Lieferanschrift maßgeblich.<br />
		<br />
		<strong id="5-2">5.2</strong><br />
		Scheitert die Zustellung der Ware aus Gründen, die der Kunde zu vertreten hat, trägt der Kunde die dem Verkäufer hierdurch entstehenden angemessenen Kosten. Dies gilt im Hinblick auf die Kosten für die Hinsendung nicht, wenn der Kunde sein Widerrufsrecht wirksam ausübt. Für die Rücksendekosten gilt bei wirksamer Ausübung des Widerrufsrechts durch den Kunden die in der Widerrufsbelehrung des Verkäufers hierzu getroffene Regelung.<br />
		<br />
		<strong id="5-3">5.3</strong><br />
		Bei Selbstabholung informiert der Verkäufer den Kunden zunächst per E-Mail darüber, dass die von ihm bestellte Ware zur Abholung bereit steht. Nach Erhalt dieser E-Mail kann der Kunde die Ware nach Absprache mit dem Verkäufer am Sitz des Verkäufers abholen. In diesem Fall werden keine Versandkosten berechnet.<br />
		<br />
		<strong id="5-4">5.4</strong><br />
		Gutscheine werden dem Kunden ausschließlich per E-Mail überlassen.
	</>,
	<h2 id="6">6) Eigentumsvorbehalt</h2>,
	<>
		Tritt der Verkäufer in Vorleistung, behält er sich bis zur vollständigen Bezahlung des geschuldeten Kaufpreises das Eigentum an der gelieferten Ware vor.
	</>,
	<h2 id="7">7) Mängelhaftung (Gewährleistung)</h2>,
	<>
		<strong id="7-1">7.1</strong><br />
		Ist die Kaufsache mangelhaft, gelten die Vorschriften der gesetzlichen Mängelhaftung.<br />
		<br />
		<strong id="7-2">7.2</strong><br />
		Der Kunde wird gebeten, angelieferte Waren mit offensichtlichen Transportschäden bei dem Zusteller zu reklamieren und den Verkäufer hiervon in Kenntnis zu setzen. Kommt der Kunde dem nicht nach, hat dies keinerlei Auswirkungen auf seine gesetzlichen oder vertraglichen Mängelansprüche.
	</>,
	<h2 id="8">8) Einlösung von Aktionsgutscheinen</h2>,
	<>
		<strong id="8-1">8.1</strong><br />
		Gutscheine, die vom Verkäufer im Rahmen von Werbeaktionen mit einer bestimmten Gültigkeitsdauer unentgeltlich ausgegeben werden und die vom Kunden nicht käuflich erworben werden können (nachfolgend "Aktionsgutscheine"), können nur im Online-Shop des Verkäufers und nur im angegebenen Zeitraum eingelöst werden.<br />
		<br />
		<strong id="8-2">8.2</strong><br />
		Einzelne Produkte können von der Gutscheinaktion ausgeschlossen sein, sofern sich eine entsprechende Einschränkung aus dem Inhalt des Aktionsgutscheins ergibt.<br />
		<br />
		<strong id="8-3">8.3</strong><br />
		Aktionsgutscheine können nur vor Abschluss des Bestellvorgangs eingelöst werden. Eine nachträgliche Verrechnung ist nicht möglich.<br />
		<br />
		<strong id="8-4">8.4</strong><br />
		Pro Bestellung kann immer nur ein Aktionsgutschein eingelöst werden.<br />
		<br />
		<strong id="8-5">8.5</strong><br />
		Der Warenwert muss mindestens dem Betrag des Aktionsgutscheins entsprechen. Etwaiges Restguthaben wird vom Verkäufer nicht erstattet.<br />
		<br />
		<strong id="8-6">8.6</strong><br />
		Reicht der Wert des Aktionsgutscheins zur Deckung der Bestellung nicht aus, kann zur Begleichung des Differenzbetrages eine der übrigen vom Verkäufer angebotenen Zahlungsarten gewählt werden.<br />
		<br />
		<strong id="8-7">8.7</strong><br />
		Das Guthaben eines Aktionsgutscheins wird weder in Bargeld ausgezahlt noch verzinst.<br />
		<br />
		<strong id="8-8">8.8</strong><br />
		Der Aktionsgutschein wird nicht erstattet, wenn der Kunde die mit dem Aktionsgutschein ganz oder teilweise bezahlte Ware im Rahmen seines gesetzlichen Widerrufsrechts zurückgibt.<br />
		<br />
		<strong id="8-9">8.9</strong><br />
		Der Aktionsgutschein ist übertragbar. Der Verkäufer kann mit befreiender Wirkung an den jeweiligen Inhaber, der den Aktionsgutschein im Online-Shop des Verkäufers einlöst, leisten. Dies gilt nicht, wenn der Verkäufer Kenntnis oder grob fahrlässige Unkenntnis von der Nichtberechtigung, der Geschäftsunfähigkeit oder der fehlenden Vertretungsberechtigung des jeweiligen Inhabers hat.
	</>,
	<h2 id="9">9) Einlösung von Geschenkgutscheinen</h2>,
	<>
		<strong id="9-1">9.1</strong><br />
		Gutscheine, die über den Online-Shop des Verkäufers käuflich erworben werden können (nachfolgend "Geschenkgutscheine"), können nur im Online-Shop des Verkäufers eingelöst werden, sofern sich aus dem Gutschein nichts anderes ergibt.<br />
		<br />
		<strong id="9-2">9.2</strong><br />
		Geschenkgutscheine und Restguthaben von Geschenkgutscheinen sind bis zum Ende des dritten Jahres nach dem Jahr des Gutscheinkaufs einlösbar. Restguthaben werden dem Kunden bis zum Ablaufdatum gutgeschrieben.<br />
		<br />
		<strong id="9-3">9.3</strong><br />
		Geschenkgutscheine können nur vor Abschluss des Bestellvorgangs eingelöst werden. Eine nachträgliche Verrechnung ist nicht möglich.<br />
		<br />
		<strong id="9-4">9.4</strong><br />
		Pro Bestellung kann immer nur ein Geschenkgutschein eingelöst werden.<br />
		<br />
		<strong id="9-5">9.5</strong><br />
		Geschenkgutscheine können nur für den Kauf von Waren und nicht für den Kauf von weiteren Geschenkgutscheinen verwendet werden.<br />
		<br />
		<strong id="9-6">9.6</strong><br />
		Reicht der Wert des Geschenkgutscheins zur Deckung der Bestellung nicht aus, kann zur Begleichung des Differenzbetrages eine der übrigen vom Verkäufer angebotenen Zahlungsarten gewählt werden.<br />
		<br />
		<strong id="9-7">9.7</strong><br />
		Das Guthaben eines Geschenkgutscheins wird weder in Bargeld ausgezahlt noch verzinst.<br />
		<br />
		<strong id="9-8">9.8</strong><br />
		Der Geschenkgutschein ist übertragbar. Der Verkäufer kann mit befreiender Wirkung an den jeweiligen Inhaber, der den Geschenkgutschein im Online-Shop des Verkäufers einlöst, leisten. Dies gilt nicht, wenn der Verkäufer Kenntnis oder grob fahrlässige Unkenntnis von der Nichtberechtigung, der Geschäftsunfähigkeit oder der fehlenden Vertretungsberechtigung des jeweiligen Inhabers hat.
	</>,
	<h2 id="10">10) Anwendbares Recht</h2>,
	<>
		<strong id="10-1">10.1</strong><br />
		Für sämtliche Rechtsbeziehungen der Parteien gilt das Recht der Bundesrepublik Deutschland unter Ausschluss der Gesetze über den internationalen Kauf beweglicher Waren. Bei Verbrauchern gilt diese Rechtswahl nur insoweit, als nicht der gewährte Schutz durch zwingende Bestimmungen des Rechts des Staates, in dem der Verbraucher seinen gewöhnlichen Aufenthalt hat, entzogen wird.<br />
		<br />
		<strong id="10-2">10.2</strong><br />
		Ferner gilt diese Rechtswahl im Hinblick auf das gesetzliche Widerrufsrecht nicht bei Verbrauchern, die zum Zeitpunkt des Vertragsschlusses keinem Mitgliedstaat der Europäischen Union angehören und deren alleiniger Wohnsitz und Lieferadresse zum Zeitpunkt des Vertragsschlusses außerhalb der Europäischen Union liegen.
	</>,
	<h2 id="11">11) Gerichtsstand</h2>,
	<>
		Handelt der Kunde als Kaufmann, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen mit Sitz im Hoheitsgebiet der Bundesrepublik Deutschland, ist ausschließlicher Gerichtsstand für alle Streitigkeiten aus diesem Vertrag der Geschäftssitz des Verkäufers. Hat der Kunde seinen Sitz außerhalb des Hoheitsgebiets der Bundesrepublik Deutschland, so ist der Geschäftssitz des Verkäufers ausschließlicher Gerichtsstand für alle Streitigkeiten aus diesem Vertrag, wenn der Vertrag oder Ansprüche aus dem Vertrag der beruflichen oder gewerblichen Tätigkeit des Kunden zugerechnet werden können. Der Verkäufer ist in den vorstehenden Fällen jedoch in jedem Fall berechtigt, das Gericht am Sitz des Kunden anzurufen.
	</>,
	<h2 id="12">12) Alternative Streitbeilegung</h2>,
	<>
		<strong id="12-1">12.1</strong><br />
		Die EU-Kommission stellt im Internet unter folgendem Link eine Plattform zur Online-Streitbeilegung bereit: https://ec.europa.eu/consumers/odr
		Diese Plattform dient als Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten aus Online-Kauf- oder Dienstleistungsverträgen, an denen ein Verbraucher beteiligt ist.<br />
		<br />
		<strong id="12-2">12.2</strong><br />
		Der Verkäufer ist zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder verpflichtet noch bereit.
		<br />&nbsp;
		<br />&nbsp;
	</>,
]

export default agb