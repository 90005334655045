import React from 'react'

const CartContext = React.createContext({
	paidFor: false,
	setPaidFor: () => {},
	payAbort: false,
	setPayAbort: () => {},
	bottles: 0,
	setBottles: () => {},
	boxes: 0,
	setBoxes: () => {},
	discount: 0,
})

export default CartContext