const parallaxData = {
	timeout: 444,
	nav: [{
		start: 0,
		duration: '16vh',
		startOffset: '32vh',
		properties: [
			{
				startValue: 200,
				endValue: 0,
				property: 'translateY',
				unit: '%',
			},
			{
				startValue: 0,
				endValue: 1,
				property: 'opacity',
			},
		],
		easing: 'easeOut',
		//animateWhenNotInViewport: true,
	}],
	title: [{
		start: 0,
		duration: '24vh',
		//startOffset: '8vh',
		startOffset: '24vh',
		properties: [
			{
				startValue: 0,
				endValue: 32,
				property: 'translateY',
				unit: 'vh',
			},
			{
				startValue: 1,
				endValue: 0,
				property: 'opacity',
			},
		],
		//easing: 'easeIn',
		//animateWhenNotInViewport: true,
	}],
	subtitle: [{
		start: 0,
		duration: '48vh',
		//startOffset: '8vh',
		startOffset: '16vh',
		properties: [
			{
				startValue: 0,
				endValue: 64,
				property: 'translateY',
				unit: 'vh',
			},
			{
				startValue: 1,
				endValue: 0,
				property: 'opacity',
			},
		],
		//easing: 'easeIn',
		//animateWhenNotInViewport: true,
	}],
	bodyTitle: [{
		start: '0',
		duration: '48vh',
		startOffset: '16vh',
		properties: [
			{
				startValue: -20,
				endValue: 0,
				property: 'translateY',
				unit: 'vh',
			},
			{
				startValue: 0,
				endValue: 1,
				property: 'opacity',
			},
		],
		easing: 'easeOut',
		//animateWhenNotInViewport: true,
	}],
	content: [{
		start: '32vh',
		duration: '32vh',
		//startOffset: '32vh',
		properties: [
			{
				startValue: -12,
				endValue: 0,
				property: 'translateY',
				unit: 'vh',
			},
			{
				startValue: 0,
				endValue: 1,
				property: 'opacity',
			},
		],
		easing: 'easeOut',
		//animateWhenNotInViewport: true,
	}],
	nextBtn: [{
		start: '90%',
		duration: '6rem',
		end: '100%',
		properties: [
			{
			startValue: 0,
			endValue: 1,
			property: 'opacity',
			//unit: 'vh',
			},
			/* {
			startValue: -3,
			endValue: 0,
			property: 'translateY',
			unit: 'rem',
			}, */
		],
	}],
	shade: [{
		start: 0,
		duration: '32vh',
		//end: '100%',
		properties: [
			{
			startValue: 1,
			endValue: 0,
			property: 'opacity',
			},
		],
	}],
	//animateWhenNotInViewport: true,
}

export default parallaxData