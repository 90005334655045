import React, { useState, useEffect, useCallback } from 'react'
import './App.scss'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import InvoiceDoc from './components/InvoiceDoc'
import parallaxData from './util/parallaxData'


import CartContext from './context/CartContext'
import ShippingContext from './context/ShippingContext'


import { isMobile, isMobileOnly, deviceType } from 'react-device-detect'
import Plx from 'react-plx/lib/Plx'

import ProductsView from './components/ProductsView'
import CartView from './components/CartView'

import GlobalContext from './context/GlobalContext'
import { withRouter, useLocation } from 'react-router-dom'

import viewData from './util/viewData'
import DesktopView from './components/PageView'
import HeaderMenu from './components/HeaderMenu'

import { scheme } from './components/InvoiceDoc'
import AgeVerify from './components/AgeVerify'
import AllowCookies from './components/AllowCookies'
import { getCookie } from './util/cookies'

import ReactGA from 'react-ga'


/* const CartIcon = props => (
	<svg xmlns='http://www.w3.org/2000/svg' {...props} viewBox='0 0 512 512'>
		<title>Warenkorb</title>
		<circle cx='176' cy='416' r='32' />
		<circle cx='400' cy='416' r='32' />
		<polygon points='167.78 304 429.12 304 467.52 112 133.89 112 125.42 64 32 64 32 96 98.58 96 146.58 368 432 368 432 336 173.42 336 167.78 304' />
	</svg>
) */
//const BasketIcon = props => (
const CartIcon = props => (
	<svg xmlns='http://www.w3.org/2000/svg' {...props} viewBox='0 0 512 512'>
		<title>Warenkorb</title>
		<path d='M339.2,217.6,256,106.67,172.8,217.6l-25.6-19.2,96-128a16,16,0,0,1,25.6,0l96,128Z' />
		<path d='M441.59,192H70.41a12,12,0,0,0-11.68,14.77L112.59,434H399.41l53.86-227.23A12,12,0,0,0,441.59,192ZM256,351.66A37.71,37.71,0,1,1,293.89,314,37.88,37.88,0,0,1,256,351.66Z' />
	</svg>
)


const App = props => {
	const [paidFor, setPaidFor] = useState(false)
	const [payAbort, setPayAbort] = useState(false)
	const [bottles, setBottles] = useState(0)
	const [boxes, setBoxes] = useState(0)
	const [cartOpen, openCart] = useState(false)
	const [paypalConnected, setPaypalConnected] = useState(false)
	const [logoWidth, setLogoWidth] = useState(0)
	const [currentPage, setCurrentPage] = useState('')
	const [discount, setDiscount] = useState(0)
	const [invoiceBody, setInvoiceBody] = useState(scheme)
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [fullName, setFullName] = useState('')
	const [addressLine1, setAddressLine1] = useState('')
	const [city, setCity] = useState('')
	const [state, setState] = useState('')
	const [postalCode, setPostalCode] = useState('')
	const countryCode = 'DE'
	const [menuOpen, openMenu] = useState(false)
	const [invoiceID, setInvoiceID] = useState(props.match.params && props.match.params.invoiceID ? props.match.params.invoiceID : '')

	const location = useLocation()

	const [inStock, setInStock] = useState({ bottle: -1, box: -1 })
	const [hasLoaded, setHasLoaded] = useState(0)
	const [isLoading, setIsLoading] = useState(1)
	const [ageVerified, setAgeVerified] = useState(false)
	const [allowCookies, setAllowCookies] = useState(getCookie('allowCookies'))
	//const isMobile = isMobileOnly

	const [email, setEmail] = useState('')
	const [hasCookie, setHasCookie] = useState(false)


	const cartData = useCallback(() => {
		const mwst = 16

		const price = () => {
			const products = viewData.filter(v => v.product)
			const prices = {}
			const tax = {}

			for (const product of products) {
				const prc = !discount
					? product.details.price
					: Math.round((100 - discount) * product.details.price) / 100
				prices[product.product] = prc
				tax[product.product] = prc / (100 + mwst) * mwst
				//tax[product.product] = parseInt(100 * prc / (100 + mwst) * mwst) / 100
			}

			return {
				mwst,
				tax,
				...prices
			}
		}

		const total = (bottles * price().bottle.toFixed(2)) + (boxes * price().box.toFixed(2))// !!
		const tax_total = (price().tax.bottle.toFixed(2) * bottles) + (price().tax.box.toFixed(2) * boxes)
		const netto = total - tax_total
		const stock = {
			bottle: inStock ? inStock.bottle : 999,
			box: inStock ? inStock.box : 99,
		}
		return {
			price: price(),
			discount,
			total,
			tax_total,
			netto,
			stock,
		}
	}, [bottles, boxes, inStock, discount])


	useEffect(() => {

		if (isMobile
			&& !document.getElementById('root').classList.contains('mobile')
			&& !document.getElementById('root').classList.contains('desktop')
		)
			document.getElementById('root').classList.add('mobile')
		else
			document.getElementById('root').classList.add('desktop')
		if (deviceType === 'tablet' && !document.getElementById('root').classList.contains('tablet'))
			document.getElementById('root').classList.add('tablet')

		for (const view of viewData)
			for (const img of view.images)
				new Image().src = process.env.REACT_APP_URL + img


		let to
		if (!paypalConnected) {
			const script = document.createElement('script')
			script.id = 'PayPalBtns'
			//script.src = 'https://www.paypal.com/sdk/js?currency=EUR&buyer-country=DE&locale=de_DE&client-id=' + process.env.REACT_APP_PP_CLIENT
			script.src = 'https://www.paypal.com/sdk/js?currency=EUR&client-id=' + process.env.REACT_APP_PP_CLIENT
			to = () => setTimeout(() => setPaypalConnected(true), 10)
			script.addEventListener('load', to)
			document.body.appendChild(script)
		}


		fetch(process.env.REACT_APP_INV_URL + '/?stock=1')
			.then(res => res.json())
			.then(res => {
				//setInStock({bottle:parseInt(res.bottles), box:parseInt(res.boxes)})
				setInStock({
					bottle: Math.round(parseInt(res.boxes) / 2) + 10,
					box: res.boxes - Math.round(parseInt(res.boxes) / 2) - 10,
				})
			})
			.catch(err => console.error(err))

		return () => clearTimeout(to)

	}, [])

	useEffect(() => {
		if (paypalConnected && !hasLoaded)
			setHasLoaded(1)
	}, [paypalConnected, hasLoaded])


	useEffect(() => {
		if (ageVerified)
			if (document && document.getElementsByClassName('logo').length && document.getElementsByClassName('logo').length > 1)
				setLogoWidth(document.getElementsByClassName('logo')[1].getBoundingClientRect().width)
	}, [ageVerified])//eslint-disable-line


	useEffect(() => {
		//if (cartData().total && bottles + boxes > 1) {
		if (bottles || boxes) {
			openCart(true)
			const to = setTimeout(() => openCart(false), 666)
			return () => clearTimeout(to)
		}
	}, [bottles, boxes, cartData])


	useEffect(() => {
		const pn = location.pathname && location.pathname !== '/'
			? location.pathname.substr(-1, 1) === '/'
				? location.pathname.substr(1, (location.pathname.length - 2))
				: location.pathname.substr(1)
			//: 'spirit'
			: 'explore'
		if (viewData.filter(v => v.name === pn).length
			|| pn === 'cart'
			|| pn.substr(0, 7) === 'invoice'
		)
			setCurrentPage(pn)
		else
			props.history.push('/')// !! 404 !!
	}, [location.pathname])//eslint-disable-line

	useEffect(() => {
		ReactGA.pageview(location.pathname + location.search)
	}, [location])

	useEffect(() => {
		if (!isMobile) return () => { }

		openMenu(false)
		document.getElementsByTagName('html')[0].scrollTop = 0
	}, [currentPage])


	useEffect(() => {
		if (!ageVerified) return () => { }
		//document.getElementById('age-verification').classList.add('slow')
		document.getElementById('age-verification').classList.add('animated')
		document.getElementById('age-verification').classList.add('fadeOut')

		loadScript(
			'https://www.googletagmanager.com/gtag/js?id=UA-97304233-3',
			() => {
				loadScript(process.env.REACT_APP_URL + '/ga.js', () => {
					ReactGA.initialize('UA-97304233-3')
					ReactGA.pageview(window.location.pathname + window.location.search)
				})
			})
	}, [ageVerified])


	return (
		<>

			{!allowCookies ?
				<AllowCookies setAllowCookies={setAllowCookies} />
				:
				<AgeVerify setAgeVerified={setAgeVerified} setHasCookie={setHasCookie} />
			}

			<GlobalContext.Provider value={{ currentPage, setCurrentPage, menuOpen, openMenu, cartData }}>

				<CartContext.Provider value={{
					paidFor, setPaidFor,
					payAbort, setPayAbort,
					bottles, setBottles,
					boxes, setBoxes,
					discount, setDiscount
				}}>

					<HeaderMenu isMobile={isMobile} cartOpen={cartOpen} cartData={cartData} CartIcon={CartIcon} />

					{isMobile ?
						<Plx id="menuBtn" parallaxData={parallaxData.nav}>
							<button onClick={() => openMenu(!menuOpen)}>
								menu
							{bottles || boxes ?
									<span className={cartOpen ? ("animated fadeOut") : ('hidden')}>{cartData().total.toFixed(2)}€</span>
									: null}
							</button>
						</Plx>
						: null}

					<ShippingContext.Provider value={{
						firstName, setFirstName,
						lastName, setLastName,
						fullName, setFullName,
						addressLine1, setAddressLine1,
						city, setCity,
						state, setState,
						postalCode, setPostalCode,
						countryCode,
						invoiceBody, setInvoiceBody,
						invoiceID, setInvoiceID,
						email, setEmail,
					}}>


						<TransitionGroup component="main">

							{viewData.map(page => {
								//console.log(currentPage)
								return page.map && page.name === currentPage
									? <CSSTransition
										key={'view' + page.name}
										timeout={parallaxData.timeout}
										classNames="transition"
										//mountOnEnter={true}
										unmountOnExit={true}
									>
										<DesktopView path={page.name} isMobile={isMobile} logoWidth={logoWidth} />
									</CSSTransition>
									: null
							})}

							{(currentPage === 'products')
								? <CSSTransition classNames="transition" timeout={parallaxData.timeout}>
									<ProductsView isMobile={isMobile} />
								</CSSTransition>
								: null}

							{(currentPage === 'cart')
								? <CSSTransition classNames="transition" timeout={parallaxData.timeout} unmountOnExit={true}>
									<CartView state={{ cartData }} isMobile={isMobile} hasCookie={hasCookie ? true : false} />
								</CSSTransition>
								: null}

							{(currentPage.substr(0, 7) === 'invoice')
								? <CSSTransition classNames="transition" timeout={parallaxData.timeout} unmountOnExit={true}>
									<InvoiceDoc />
								</CSSTransition>
								: null}


						</TransitionGroup>

					</ShippingContext.Provider>
				</CartContext.Provider>

				{!isMobile ?
					<button id="legal" className="ghost light" onClick={e => props.history.push('/legal')}>Impressum & Datenschutz</button>
					: null}

			</GlobalContext.Provider>

		</>
	)
}

export default withRouter(App)


const loadScript = (src, callback) => {
	let r = false
	const s = document.createElement('script')
	s.type = 'text/javascript'
	s.async = 'true'
	s.src = src
	s.onload = s.onreadystatechange = function () {
		if (!r && (!this.readyState || this.readyState === 'complete')) {
			r = true
			if (callback)
				callback()
		}
	}
	document.body.appendChild(s)
}