import React, { useContext } from 'react'
import GlobalContext from '../context/GlobalContext'
import { useHistory, NavLink } from 'react-router-dom'

const HeaderMenu = props => {
	const { isMobile, cartOpen, cartData, CartIcon } = props
	const { currentPage, menuOpen, openMenu } = useContext(GlobalContext)
	const history = useHistory()

	return (
		<header
			className={menuOpen?
				'active'
			:null}
		>
			<p><NavLink
				title="Explore"
				exact to="/explore"
				activeClassName="active"
				isActive={(match, location) => {
					if (match || !location.pathname || location.pathname === "/")
						return true;
					return false
				}}
				onClick={e => {
					e.preventDefault()
					if (currentPage !== 'explore') {
						//setCurrentPage('explore')
						history.push('/explore')
					}
				}}
			>
				Explore
			</NavLink></p>
			<p><NavLink
				title="Spirit"
				to={"/spirit"}
				activeClassName="active"
				onClick={e => {
					e.preventDefault()
					if (currentPage !== 'spirit') {
						//setCurrentPage('spirit')
						history.push('/spirit')
					}
				}}
			>
				Spirit
			</NavLink></p>

			<h1 className="logo" onClick={() => openMenu(!menuOpen)}>LVIVSKA</h1>

			<p><NavLink
				title="Quality"
				exact to="/quality"
				activeClassName="active"
				onClick={e => {
					e.preventDefault()
					if (currentPage !== 'quality') {
						//setCurrentPage('quality')
						history.push('/quality')
					}
				}}
			>
				Quality
			</NavLink></p>
			<p><NavLink
				title="Products"
				to="/products"
				activeClassName="active"
				onClick={e => {
					e.preventDefault()
					if (currentPage !== 'products') {
						//setCurrentPage('products')
						history.push('/products')
					}
				}}
			>
				Products
			</NavLink></p>

			{isMobile ?
				<button id="legal" className="light" onClick={e => history.push('/legal')}>Impressum & Datenschutz</button>
				: null}

			{cartData().total ? (
				<aside
					id="cart"
					className={cartOpen ? ('open animated fadeInRight fast') : ('animated fadeInRight fast')}
					onClick={e => {
						e.preventDefault()
						if (currentPage !== 'cart') {
							//setCurrentPage('cart')
							history.push('/cart')
						}
					}}
				>
					<CartIcon id="cartIcon" />
					{cartData().total ? <small>{cartData().total.toFixed(2)}€</small> : null}
				</aside>
			) : null}

		</header>
	)
}

export default HeaderMenu
//export default withRouter(HeaderMenu)