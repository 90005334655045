import React from 'react'
import dsb from './dsb'
import agb from './agb'
import widerruf from './widerruf'

const viewData = [
	{
		name: 'explore',
		label: 'Explore',
		next: 'spirit',
		map: true,
		//images: ['/assets/ilsang-moon-tXqVe7oO-go-unsplash.jpg'],
		//images: ['/assets/john-mark-kuznietsov-134302-unsplash.jpg'],
		images: ['/assets/lvivska-explore.jpg'],
		title: 'Explore for yourself.',
		subTitle: 'Die Geschichte von Vodka LVIVSKA beginnt im Jahre 1782 n. Chr. in der ersten ukrainischen Vodka-Manufaktur in Lviv (ehemals Lemberg, Österreich).',
		content: [
			<>Weit über die Grenzen der Ukraine hinaus, ist Lviv als “Stadt der schlafenden Löwen” bekannt. Für viele ist sie die schönste und mysthischste Stadt Osteuropas. Sie hat gleichermaßen eine lange Geschichte, beeindruckende Architektur, junge Menschen, Kunst, Kultur und ihre Altstadt ist Teil des UNESCO Weltkulturerbes.</>,
			<>Lviv hat viel zu bieten – machen Sie sich auf, und entdecken diese wunderschöne Stadt für sich selbst!</>,
		],
		logo: 'ADD',
		margin: 21,
		padding: '0',
		fit: 'contain',
		orientation: 'LEFT',
	},
	{
		name: 'spirit',
		label: 'Spirit',
		next: 'quality',
		map: true,
		//images: ['/assets/new/isis-franca-wIQG-3H3s1g-unsplash.jpg'],
		images: ['/assets/lvivska-spirit.jpg'],
		title: 'A true experience.',
		subTitle: 'Nach dem ukrainischen und russischem Reinheitsgebot darf ein Vodka der Standardklasse nicht mehr als 0,3% Methylalkohol enthalten, um auf dem Markt zugelassen werden zu dürfen.',
		content: [
			<>Einige europäische Vodkamarken überschreiten diesen Grenzwert deutlich, trotzdem werden sie als Premium-Vodka angeboten.</>,
			<>Ein Lux-Klasse Vodka darf den Methylalkohol-Anteil von nur 0,02% nicht übersteigen und ist damit weitgehendst vom unerwünschten Methanol gesäubert.<br />		Vodka LVIVSKA unterschreitet diese Höchstgrenze noch und wird in der Ukraine mit einem Anteil von nur 0,01% Methylalkohol produziert. Dadurch kommt der wunderbar weiche und sanfte Geschmack zustande.</>,
		],
		logo: 'ADD',
		margin: 16,
		padding: '0',
		fit: 'contain',
		orientation: 'RIGHT',
	},
	{
		name: 'products',
		label: 'Products',
		next: false,
		map: false,
		//images: ['/assets/Lvivska-Bottle_3D-Studio6-1-web.jpg'],
		images: ['/assets/lvivska-produtcs.jpg'],
		title: 'We love vodka.',
		subTitle: 'Kaufen Sie hier unsere Produkte.',
		//product: true,
		content: [
			null,
		]
	},
	/* {
		name: 'products/bottle',
		label: 'LVIVSKA Vodka',
		next: 'products/box',
		map: true,
		images: ['/assets/Lvivska_bottle-Aufsteller-6_Camera_5-CloseUp_a_i5_0029.jpg'],
		title: 'LVIVSKA Vodka — 0,5 Liter',
		subTitle: 'Null komma fünf Liter in einer eleganten Flasche',
		product: 'bottle',
		details: {
			price: 23.95,
			weight: .5,
			B: 9, H: 27, T: 9,
			description: '',
		},
		content: [
			<>LVIVSKA Vodka wird sorgfältig veredelt und gereinigt, bis er die höchste Qualität der drei Vodka-Klassen erreicht: Die LUX-Klasse.</>,
			<>Nach dem ukrainischen und russischem Reinheitsgebot, darf ein Vodka der Standardklasse, gemessen am Anteil reinen Alkohols, nicht mehr als 0,3% Methylalkohol enthalten, damit er überhaupt auf den Markt  gebracht werden darf.<br />
			Die meisten europäischen Vodkamarken überschreiten diesen Grenzwert deutlich, trotzdem werden sie als Premium-Vodka angeboten.</>,
			<>Ein LUX-Klasse Vodka darf den Methylalkohol-Anteil von nur 0,02% nicht übersteigen und ist damit weitgehendst vom unerwünschten Methanol gesäubert.<br />
			Vodka LVIVSKA unterschreitet diese Höchstgrenze noch und wird mit einem Anteil von nur 0,01% Methylalkohol in der Ukraine produziert. Dadurch kommt der wunderbar weiche und sanfte Geschmack zustande.</>,
		],
		logo: 'ADD',
		margin: null,
		padding: null,
		fit: 'contain',
		orientation: 'LEFT',
	}, */
	{
		name: 'products/bottle',
		label: 'LVIVSKA Gold',
		next: 'products/box',
		map: true,
		//images: ['/assets/Lvivska_bottle-Aufsteller-6_Camera_5-CloseUp_a_i5_0029.jpg', '/assets/_59_7685-web.jpg'],
		images: ['/assets/lvivska-bottle-1.jpg', '/assets/lvivska-bottle-2.jpg'],
		title: 'LVIVSKA Gold — 0,7 Liter',
		subTitle: 'Null komma sieben Liter LUX-Klasse Vodka',
		product: 'bottle',
		details: {
			price: 26.95,
			weight: .75,
			B: 9, H: 27, T: 9,
			description: 'Diese Ausführung wird an unsere übliche Filtration durch Qualitäts­birkenholzkohlen anschließend in einem langwierigen Prozess durch Honigfiltration veredelt.',
		},
		content: [
			<>Je nach Reinheits&shy;grad wird handelsüblicher Vodka in 3 Kategorien aufgeteilt:<br />
			<strong>Standard</strong> (einfach), <strong>Extra</strong> (gehoben), und <strong>Lux</strong> (Spitzenklasse).</>,
			<>Hauptkriterium bei der Klassifizierung ist der Methylakohol welcher, bis auf sehr wenige Ausnahmen, in fast allen gebrannten Spiritousen vorkommt. Beispielsweise sind in Williamsbränden die Konzentrationen oft hundertfach höher, als in gewöhnlichem Vodka. Trotzdem kann man diese Brände unbedenklich genießen. Zuviel Methylalkohol kann aber unangenehme Nebenwirkungen wie Kopfschmerzen und Übelkeit verursachen.</>,
			<>Im Gegensatz zu fast allen anderen Bränden, wird bei der gewissenhaften Vodkaproduktion deshalb soviel Methylakohol wie möglich elemeniert. Nicht umsonst gilt guter Vodka deshalb als die reinste aller Spiritousen.</>,
		],
		logo: 'ADD',
		margin: null,
		padding: null,
		fit: 'contain',
		orientation: 'LEFT',
	},
	{
		name: 'products/box',
		label: 'LVIVSKA Box',
		//next: 'products/bottle-m',
		next: 'products/bottle',
		map: true,
		//images: ['/assets/_59_9997.jpg', '/assets/_59_9985.jpg'],
		images: ['/assets/lvivska-box-1.jpg', '/assets/lvivska-box-2.jpg'],
		title: 'LVIVSKA Gold — Geschenkbox',
		subTitle: 'Geschenkkarton mit zwei LVIVSKA-Schnapsgläsern',
		product: 'box',
		details: {
			price: 29.95,
			weight: .85,
			B: 18, H: 27, T: 9,
			description: 'Unser besonders weicher, honiggereinigter LUX-Klasse Vodka in der größeren 700ml-Ausführung, im Set mit zwei Schnapsgläsern — ideal auch als Geschenk.',
		},
		content: [
			<>Die Geschichte von Vodka LVIVSKA beginnt im Jahre 1782 n. Chr. in der ersten ukrainischen Vodka-Manufaktur in Lviv (ehemals Lemberg, Österreich)</>,
			<>Der seinerzeit neue Geschmack wurde damals vor allem in europäischen Adelshäusern sehr geschätzt.</>,
			<>Heute wird LVIVSKA Vodka in der Ukraine nach dem neuesten Stand der Technik produziert. Das einmalige Rezept wurde über Jahrhunderte nicht verändert. Somit gilt der Vodka aus LVIV als eines der ältesten Wässerchen der Welt.</>,
			<>Kaufen Sie hier unseren Spitzenklasse-Vodka in der Gold-Edition mit zwei Gläsern im 1782-Decor, oder verschenken Sie ihn an einen Genießer.</>,
		],
		logo: 'ADD',
		margin: null,
		padding: null,
		fit: 'contain',
		orientation: 'LEFT',
	},
	{
		name: 'quality',
		label: 'Quality',
		next: 'products',
		map: true,
		//images: ['/assets/new/sina-rezakhani-JsHu3oqSuVA-unsplash.jpg'],
		//images: ['/assets/new/maks-styazhkin-POT_-v9CUto-unsplash.jpg'],
		//images: ['/assets/new/daniel-mingook-kim-PL-YMV0QiKk-unsplash.jpg'],
		images: ['/assets/lvivska-quality.jpg'],
		title: 'Lvivska quality spirit.',
		subTitle: 'Das Verhältnis von 40 Prozent reinem Alkohol und 60 Prozent Wasser, war bis vor Kurzem in vielen traditionellen Vodka-Nationen sogar gesetzlich vorgeschrieben.',
		content: [
			<>Abweichungen waren nicht erlaubt, deshalb gilt auch heute unter Vodka-Experten nur dieses Mischverhältnis als das einzig wahre. Daher hat auch unser ukrainischer Traditions-Vodka Lvivska auch exakt 40 Prozent Volumen Alkohol.</>,
			<>Dmitri Iwanowitsch Mendelejew, der Entwickler des Perioden&shy;systems der Elemente, revolutionierte auch die Vodkaproduktion. Nach vielen Experimenten setzte sich auch in der Destille von Vodka Lvivska die dreifache Destillation durch. Kurz darauf folgte ein weiterer wichtiger Schritt bei der Vodkaproduktion: Die Filterung mit Holz- und Aktivkohle.</>,
			<>Vodka Lvivska wird seit jeher nur mit Qualitätsholzkohlen aus Eichen-, Buchen-, und Birkenholzkohlen gefiltert, das macht ihn so unverkennbar weich und rein und gibt ihm das unverwechselbare Vodkaaroma.</>,
		],
		logo: 'ADD',
		margin: -8,
		padding: '0',
		fit: 'contain',
		orientation: 'LEFT',
	},
	{
		name: 'legal',
		label: 'Impressum/Datenschutz',
		next: false,
		map: true,
		images: [null],
		title: 'Impressum',
		subTitle: <>&amp; Datenschutz&shy;bestimmungen</>,
		content: dsb,
		logo: null,
		margin: null,
		padding: null,
		fit: null,
		orientation: null,
	},
	/* {
		name: 'cart',
		map: false,
	},
	{
		name: 'invoice',
		map: false,
	}, */
	{
		name: 'agb',
		label: 'AGB',
		next: false,
		map: true,
		images: [null],
		title: <>Allgemeine Geschäfts&shy;bedingungen</>,
		subTitle: <>Allgemeine Geschäfts&shy;bedingungen mit Kunden&shy;informationen</>,
		content: agb,
		logo: null,
		margin: null,
		padding: null,
		fit: null,
		orientation: null,
	},
	{
		name: 'widerruf',
		label: 'Widerruf',
		next: false,
		map: true,
		images: [null],
		title: <>Widerrufs&shy;belehrung</>,
		subTitle: null,
		content: widerruf,
		logo: null,
		margin: null,
		padding: null,
		fit: null,
		orientation: null,
	},
]

export default viewData