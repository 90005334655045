import React, { useContext, useState, useEffect } from 'react'
import ShippingContext from '../context/ShippingContext'

export const scheme = {
	invoice_number: 0,
	response: {
		detail: {
			invoice_date: "",
			currency_code: "",
			payment_term: {
				term_type: "",
			},
			shipping: {
				name: {
					full_name: "",
				},
				address: {
					address_line_1: "",
					admin_area_2: "",
					admin_area_1: "",
					postal_code: "",
					country_code: "",
				}
			}
		},
		invoicer: {
			name: {
				given_name: "",
				surname: "",
				full_name: "",
			},
			address: {
				address_line_1: "",
				address_line_2: "",
				postal_code: "",
				country_code: "",
			},
			website: "",
			tax_id: "",
			logo_url: "",
			additional_notes: "",
			business_name: "",
			email_address: "",
		},
		items: [
			{
				name: "",
				unit_amount: {
					currency_code: "",
					value: "",
				},
				tax: {
					currency_code: "",
					value: "",
				},
				quantity: "",
				description: "",
				sku: "",
			},
			{
				name: "",
				unit_amount: {
					currency_code: "",
					value: "",
				},
				tax: {
					currency_code: "",
					value: "",
				},
				quantity: "",
				description: "",
				sku: "",
			}
		],
		amount: {
			currency_code: "",
			value: "",
			breakdown: {
				item_total: {
					currency_code: "",
					value: "",
				},
				tax_total: {
					currency_code: "",
					value: "",
				}
			}
		},
		primary_recipients: [
			{
				name: {
					given_name: "",
					surname: "",
				},
				email_address: "",
				payer_id: "",
				address: {
					country_code: "",
				}
			}
		],
	},
	shopData: {
		firstName: "",
		lastName: "",
		addressLine1: "",
		state: "",
		city: "",
		postalCode: "",
		countryCode: "",
		email: "",
	}
}

const InvoiceDoc = props => {
	const [body, setBody] = useState(scheme)

	const { detail, invoicer, items, amount, primary_recipients } = body.response
	const { invoice_date, currency_code, shipping } = detail//eslint-disable-line
	const { name, address, website, tax_id, logo_url, additional_notes, business_name, email_address } = invoicer//eslint-disable-line
	const payer = primary_recipients[0]
	const shopData = body.shopData
	const [hasLoaded, sethasLoaded] = useState(false)


	const {
		//firstName, setFirstName,
		//lastName, setLastName,
		fullName, //setFullName,
		addressLine1, //setAddressLine1,
		city, //setCity,
		state, //setState,
		postalCode, //setPostalCode,
		countryCode,
		invoiceID,
		email
	} = useContext(ShippingContext)


	useEffect(() => {
		if (invoiceID)
			fetch(process.env.REACT_APP_INV_URL + '?inv=' + invoiceID)
				.then(res => res.json())
				.then(res => { setBody(res) })
				.catch(err => console.error(err))
		else
			setBody(scheme)

		sethasLoaded(true)
	}, [invoiceID])

	useEffect(() => {
		if (!hasLoaded || !invoiceID) return () => { }

		setTimeout(() => {
			const doc = new FormData()
			doc.append('document', document.documentElement.innerHTML)

			fetch(process.env.REACT_APP_INV_URL + '?doc=' + invoiceID, {
				method: 'post',
				body: doc
			})
		}, 2000)
	}, [hasLoaded, invoiceID])

	if (!props || !Object.keys(body).length) return <React.Fragment />

	return (
		<section id="doc-invoice">
			<div id="sheet">

				<h1><span>Lvivska-Shop </span>Rechnung</h1>

				<header>
					<p>
						{/* <img src={logo_url} alt="Firmenlogo" /> */}
						{business_name
							? <>{business_name}<br /></>
							: null
						}
						{invoicer.name.full_name}<br />
						{email_address
							? <>{email_address}<br /></>
							: null
						}
						{tax_id
							? <>Steuer-Nr.: {tax_id}<br /></>
							: null
						}
						{website
							? <>{website}</>
							: null
						}
					</p>
					<p>
						Rechnungsnummer: {body.invoice_number ? body.invoice_number : invoiceID}<br />
						Rechnungsdatum: {invoice_date ? invoice_date.substr(0, 10) : null}<br />
						Verwendungszweck: {body.invoice_number ? body.invoice_number : invoiceID}<br />
						Fällig am: {invoice_date
							? <>{invoice_date.substr(0, 10)}</>
							: null
						}
					</p>
				</header>

				{/* <h2>
					Fälliger Betrag:<br />
					{amount.value
						? amount.value
						: null
					} EUR
				</h2> */}

				<article>
					<aside>
						<h3>Rechnungsadresse:</h3>
						<p>
							{/* {shipping.business_name
								? <>{shipping.business_name}<br /></>
								: null
							} */}
							{fullName
								? <>{fullName}<br /></>
								: <>{shopData.firstName + ' ' + shopData.lastName}<br /></>
							}
							{addressLine1
								? <>{addressLine1}<br /></>
								: <>{shopData.addressLine1}<br /></>
							}
							{/* {addressLine2
								? <>{addressLine2}<br /></>
								: <>{shopData.addressLine2}<br /></>
							} */}
							{/* {addressLine3
								? <>{addressLine3}<br /></>
								: <>{shopData.addressLine3}<br /></>
							} */}
							{postalCode
								? <>{postalCode} </>
								: <>{shopData.postalCode} </>
							}
							{city
								? <>{city}<br /></>
								: <>{shopData.city}<br /></>
							}
							{state
								? <>{state}, </>
								: <>{shopData.state}, </>
							}
							{countryCode
								? <>{countryCode}<br /></>
								: <>DE<br /></>
							}
							{email
								? <><br />{email}<br /></>
								: <><br />{shopData.email}<br /></>
							}
							{payer.phones && payer.phones[0].country_code
								? payer.phones[0].country_code.replace('00', '+')
								: null
							}
							{payer.phones && payer.phones[0].national_number
								? <>{payer.phones[0].national_number}</>
								: null
							}
						</p>
					</aside>
					<aside>
						<h3>Lieferadresse:</h3>
						<p>
							{/* {shipping.business_name
								? <>{shipping.business_name}<br /></>
								: null
							} */}
							{fullName
								? <>{fullName}<br /></>
								: <>{shopData.firstName + ' ' + shopData.lastName}<br /></>
							}
							{addressLine1
								? <>{addressLine1}<br /></>
								: <>{shopData.addressLine1}<br /></>
							}
							{/* {addressLine2
								? <>{addressLine2}<br /></>
								: <>{shopData.addressLine2}<br /></>
							} */}
							{/* {addressLine3
								? <>{addressLine3}<br /></>
								: <>{shopData.addressLine3}<br /></>
							} */}
							{postalCode
								? <>{postalCode} </>
								: <>{shopData.postalCode} </>
							}
							{city
								? <>{city}<br /></>
								: <>{shopData.city}<br /></>
							}
							{state
								? <>{state}, </>
								: <>{shopData.state}, </>
							}
							{countryCode
								? <>{countryCode}<br /></>
								: <>DE<br /></>
							}
							{/* {email
								? <>{email}<br /></>
								: <><br />{shopData.email}<br /></>l
							} */}
							{/* {shipping.phones && shipping.phones[0].country_code
								? shipping.phones[0].country_code.replace('00','+')
								: null
							}
							 {shipping.phones && shipping.phones[0].national_number
								? <>{shipping.phones[0].national_number}</>
								: null
							} */}
						</p>
					</aside>

					<div key={Math.round(1000000 * Math.random())}>
						<p>Artikel&shy;beschreibung</p>
						<p>Anzahl</p>
						<p>Netto</p>
						<p>Brutto</p>
						<span className="clear" />

						{items.map(item => {
							return (
								<React.Fragment key={Math.random() * 1000}>
									{item.name ? <p>{item.name}</p> : null}
									{item.quantity ? <p>{item.quantity}</p> : null}
									{item.unit_amount ? <p>{item.unit_amount.value} {currency_code}</p> : null}
									{item.unit_amount ? <p>{(parseFloat(item.unit_amount.value) + parseFloat(item.tax.value)).toFixed(2)} {currency_code}</p> : null}
									<span className="clear" />
									{item.description ? <small>{item.description}</small> : null}
									<span className="clear" />
								</React.Fragment>
							)
						})}
					</div>
				</article>

				<article>
					<p>
						Zwischensumme<br />
						Einschließlich MwSt. (19%)<br />
						Versandkosten<br />
						Gesamt
					</p>
					<p>
						{amount.breakdown.item_total.value} EUR<br />
						{amount.breakdown.tax_total.value} EUR<br />
						{amount.breakdown.shipping && amount.breakdown.shipping.value !== '0.00' ? amount.breakdown.shipping.value + ' EUR' : 'GRATIS'}<br />
						<strong>{amount.value} EUR</strong>
					</p>
				</article>

				<p>{additional_notes ? additional_notes : null}</p>
				{/* Rücknahme- und Stornierungsbedingungen */}

			</div>
		</section>
	)
}

export default InvoiceDoc