import React from 'react'
import Plx from 'react-plx'
import parallaxData from '../util/parallaxData'
import { useHistory } from 'react-router'
import viewData from '../util/viewData'

const ProductsView = props => {
	const isMobile = props.isMobile
	const { title, subTitle, images, next } = viewData.filter(v => (v.name.substr(0,9) === 'products'))[0]
	const products = viewData.filter(v => (v.name.substr(0,9) === 'products/'))
	const history = useHistory()
	
	return (
		<section id="page-products">


			{isMobile ? <>
					<figure>
						<Plx parallaxData={parallaxData.shade}>
							<span />
						</Plx>
						<img src={process.env.REACT_APP_URL + images[0]} alt="" />
						<Plx parallaxData={parallaxData.title}>
							<h1 className="animated fadeIn slow">{title}</h1>
						</Plx>
						<Plx parallaxData={parallaxData.subtitle}>
							<h2 className="animated fadeIn slow">{subTitle}</h2>
						</Plx>
					</figure>
					{/* <h1 className="logo" onClick={() => openMenu(!menuOpen)}>LVIVSKA</h1> */}
			</> : null}


			{products.map((product, i) => {
				const timeout = i * parallaxData.timeout * 3
				return (
					<article key={'products-'+product.name}>
						<h1 className={"fadeIn animated"} style={{ animationDelay: (parallaxData.timeout * 1 / 1000 + (timeout / 1000)) + 's' }}>
							{product.title}
						</h1>
						<p className={"fadeIn animated slow"} style={{ animationDelay: (parallaxData.timeout * 2 / 1000 + (timeout / 1000)) + 's' }}>
							{product.details.description}
						</p>
						<p>
							<button
								className={"push fadeIn animated slow"}
								onClick={() => history.push('/products/' + product.product)}
								style={{ animationDelay: (parallaxData.timeout * 3 / 1000 + (timeout / 1000)) + 's' }}
							>
								jetzt kaufen <span>&rarr;</span>
							</button>
						</p>
						<figure
							className={""}
							style={{
								animationDelay: (timeout + parallaxData.timeout) / 1000 + 's',
								transitionDelay: (timeout + parallaxData.timeout) / 1000 + 's',
							}}
							onClick={() => history.push('/products/' + product.product)}
						>
							<img src={process.env.REACT_APP_URL + product.images[1]} alt="" />
						</figure>
					</article>
				)
			})}


			{ props.isMobile && next ?
				<Plx parallaxData={parallaxData.nextBtn} className="next">
					<button
						className="light"
						onClick={() => history.push('/' + next)}
					>
						nächste Seite
					</button>
				</Plx>
			: null }


		</section>
	)
}

export default ProductsView