import React, { useState, useEffect, useContext, useRef } from 'react'
import PaypalBtns from './PaypalBtns'
import CartContext from '../context/CartContext'
import { findDOMNode } from 'react-dom'
import ShippingContext from '../context/ShippingContext'
import GlobalContext from '../context/GlobalContext'
//import Plx from 'react-plx'
//import parallaxData from '../util/parallaxData'
import { useHistory } from 'react-router'
import createShipment from '../util/dhlRequest'
import { setCookie } from '../util/cookies'
import ReactGA from 'react-ga'

const calcAge = dateString => {
	const birthday = +new Date(dateString);
	return ~~((Date.now() - birthday) / (31557600000));
}

const CartView = props => {
	const { cartData } = props.state
	const [formOpen, openForm] = useState(false)
	const [formValid, setFormValid] = useState(false)
	const [footerHeight, setFooterHeight] = useState(0)
	const footerRef = useRef(null)
	const { currentPage, setCurrentPage } = useContext(GlobalContext)
	const history = useHistory()
	const [couponCode, setCouponCode] = useState('')
	const [couponOpen, openCoupon] = useState(false)
	const [isPaying, setIsPaying] = useState(false)
	const [ageVerified, setAgeVerified] = useState(!props.hasCookie)
	const [ageInput, setAgeInput] = useState('')

	const {
		paidFor, //setPaidFor,
		payAbort, setPayAbort,
		bottles, setBottles,
		boxes, setBoxes,
		//invoiceBody, setInvoiceBody,
		/* discount, */ setDiscount,
	} = useContext(CartContext)

	const {
		firstName, setFirstName,
		lastName, setLastName,
		fullName, setFullName,
		addressLine1, setAddressLine1,
		city, setCity,
		state, setState,
		postalCode, setPostalCode,
		//countryCode,
		email, setEmail,
	} = useContext(ShippingContext)

	useEffect(() => {
		if (currentPage !== 'cart') return () => { }

		if (footerRef.current && footerHeight < findDOMNode(footerRef.current).clientHeight)
			setFooterHeight(findDOMNode(footerRef.current).clientHeight)

		return () => {
			setPayAbort(false)
		}
	}, [currentPage, footerHeight, footerRef])//eslint-disable-line


	useEffect(() => {
		if (!payAbort) return () => { }

		ReactGA.event({
			category: 'Kunde',
			action: 'hat die Zahlung abgebrochen',
			label: 'pay-abort',
			value: cartData().total.toFixed(2)
		});

		const redirect = setTimeout(() => setPayAbort(false), 3000)

		return () => clearTimeout(redirect)
	}, [payAbort])//eslint-disable-line


	useEffect(() => {

		for (const input of document.body.getElementsByTagName('INPUT'))
			if (input.value && input.value !== '') input.classList.add('active')

		if (
			firstName.length < 3
			|| lastName.length < 3
			|| city.length < 3
			|| postalCode.length < 5
			|| state.length < 2
		) {
			setFormValid(false)
			return () => { }
		}

		if (!addressLine1.match(/[\w\säöüßéèêáàâóòôíìîúùû\-./]{2}\s\d/i)) {
			setFormValid(false)
			return () => { }
		}

		//const emailRexEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
		//if (!email.match(emailRexEx)) {
		if (!email.match(/[-.A-ZÄÖÜäöüß@+]{3,}@[-.A-ZÄÖÜäöüß]{2,}.[-.A-Z]{2,}$/i)) {
			setFormValid(false)
			return () => { }
		}

		setFullName(firstName + ' ' + lastName)

		const street = addressLine1.match(/([^\d]*)\s\d+/i)[1]
		const number = addressLine1.match(/[^\d]*\s([\d]+)/i)[1]

		const lag = setTimeout(() => createShipment({
			action: 'validate',
			//date,
			//weight,
			//dimensions,
			//email,
			fullName,
			street,
			number,
			zip: postalCode,
			city,
			state,
			cb: (sta, msg, url) => sta === 'ok' ? setFormValid(true) : null
		}), 100)
		return () => clearTimeout(lag)

	}, [firstName, lastName, fullName, addressLine1, city, postalCode, state, email])//eslint-disable-line

	useEffect(() => {
		if (couponCode !== 'LV2020') {
			setDiscount(0)
			ReactGA.event({
				category: 'Kunde',
				action: 'hat einen Gutschein eingelöst',
				label: 'pay-voucher'
			})
			return () => { }
		}

		setDiscount(15.0375)
		openCoupon(false)
	}, [couponCode])

	useEffect(() => {
		if (couponOpen)
			document.getElementById('coupon').focus()
	}, [couponOpen])

	useEffect(() => {
		if (paidFor)
			ReactGA.event({
				category: 'Kunde',
				action: 'hat eine Zahlung abgeschlossen',
				label: 'pay-success',
				value: cartData().total.toFixed(2)
			})

		if (paidFor || payAbort)
			setIsPaying(false)
	}, [paidFor, payAbort])


	useEffect(() => {
		if (!ageInput.match(/[1-2][0-9][0-9][0-9]-[0-1][0-9]-[0-3][0-9]/) || calcAge(ageInput) <= 18)
			return () => { }

		setCookie('ageIsVerified', true, 2)
		setAgeVerified(true)

	}, [ageInput])


	return (
		<section
			id="page-cart"
		>
			<article>
				<h1>Warenkorb</h1>
				{bottles || boxes ? <>
					<aside id="cart_summary" className={formOpen ? 'closed' : null}>

						<div id="couponInput" className={couponOpen ? 'open' : null}>
							<input name="coupon" id="coupon" type="text" value={couponCode} onChange={e => setCouponCode(e.target.value)} placeholder="Gutscheincode" />
							<button onClick={() => openCoupon(false)}>OK</button>
						</div>


						<p className="legal">
							<button id="widerruf" className="legal light small" onClick={e => history.push('/widerruf')}>Widerrufsrecht</button>
							<button id="agb" className="legal light small" onClick={e => history.push('/agb')}>AGB</button>
						</p>


						{bottles ? <>
							<p className="item">
								<span>LVIVSKA Gold – 0,7l-Flasche
									<br />
									<small>Null komma sieben Liter LUX-Klasse Vodka</small>
								</span>
								<button className="ghost" onClick={() => { if (bottles) setBottles(bottles - 1) }}>
									–
								</button>{bottles}x<button className="ghost" onClick={() => setBottles(bottles + 1)}>
									+
								</button>
							</p>
							{/* <p className="clear" /> */}
						</> : null}

						{boxes ? <>
							<p className="item">
								<span>LVIVSKA Gold – 0,7l-Geschenkbox
									<br />
									<small>Geschenkkarton mit zwei LVIVSKA-Schnapsgläsern</small>
								</span>
								<button className="ghost" onClick={() => { if (boxes) setBoxes(boxes - 1) }}>
									–
								</button>{boxes}x<button className="ghost" onClick={() => { if (boxes) setBoxes(boxes + 1) }}>
									+
								</button>
							</p>
							{/* <p className="clear" /> */}
						</> : null}

						<p>
							<span>Netto:</span>		<span>{(cartData().total - cartData().tax_total).toFixed(2)}</span>
							<br />
							<strong>Brutto:</strong>		<strong>{cartData().total.toFixed(2)}</strong>
							<br />
							{cartData().discount ?
								<>
									<span className="discount">inkl. Rabatt: {Math.round(cartData().discount)}%</span>
									<br />
								</>
								: null}
							{bottles + boxes > 1
								?
								<span className="shipping">Versandkosten: Gratis</span>
								:
								<span className="shipping">Versandkosten: 1€ — gratis ab 2 Produkten</span>
							}
						</p>

						<button className="order" onClick={() => openForm(!formOpen)}>jetzt bestellen</button>
						<button className="order light ghost" onClick={() => openCoupon(true)}>Gutschein einlösen</button>


					</aside>

					<footer className={formOpen ? null : 'closed'}>
						<button className="ghost primary" onClick={() => openForm(!formOpen)}>Bestellung überprüfen <span>&#8679;</span></button>

						{!ageVerified ? <>
							<h2 style={{ textAlign: 'center' }}><br />Volljährigkeit bestätigen</h2>
							<div style={{ position: 'relative', width: '100%', maxWidth: '240px', margin: '0 auto', textAlign: 'center' }}>
								<label htmlFor="birthday">Geburtsdatum:</label><br />
								<input type="date" name="birthday" placeholder="YYYY-MM-DD" value={ageInput} onChange={e => { setAgeInput(e.target.value) }} />
								<input type="hidden" value={ageInput} readOnly />

							</div>
						</> : <>


								<form className={formOpen ? 'open' : null}>
									<h2>Liefer&shy;adresse</h2>
									<div className="input">
										<input type="text" name="firstName" value={firstName} onChange={e => setFirstName(e.target.value)} />
										<input type="text" value={firstName} readOnly />
										<span>Vorname</span>
									</div>
									<div className="input">
										<input type="text" name="lastName" value={lastName} onChange={e => setLastName(e.target.value)} />
										<input type="text" value={lastName} readOnly />
										<span>Nachname</span>
									</div>
									<div className="input wide">
										<input type="email" name="email" value={email} onChange={e => setEmail(e.target.value)} />
										<input type="email" value={email} readOnly />
										<span>E-Mail</span>
									</div>
									<div className="input">
										<input type="text" name="addressLine1" value={addressLine1} onChange={e => setAddressLine1(e.target.value)} />
										<input type="text" value={addressLine1} readOnly />
										<span>Adresse, Hausnr.</span>
									</div>
									<div className="input">
										<input type="text" name="postal-code" value={postalCode} maxLength={5} onChange={e => setPostalCode(e.target.value)} />
										<input type="text" value={postalCode} readOnly />
										<span>Postleitzahl</span>
									</div>
									<div className="input">
										<input type="text" name="city" value={city} onChange={e => setCity(e.target.value)} />
										<input type="text" value={city} readOnly />
										<span>Ort</span>
									</div>
									<div className="input">
										<input type="text" name="state" value={state} onChange={e => setState(e.target.value)} />
										<input type="text" value={state} readOnly />
										<span>Bundesland</span>
									</div>
									{/* <div>
										<p>Deutschland</p>
									</div> */}
								</form>
							</>}

					</footer>

					{ageVerified ?
						<div id="payBtns" className={formOpen && formValid ? null : 'closed'}>
							<PaypalBtns cartData={cartData} setCurrentPage={setCurrentPage} setIsPaying={setIsPaying} />
						</div> : null}

				</> : <>
						<span className="light">Ihr Warenkorb ist leer.</span>
					</>
				}


				{isPaying ?
					<aside id="payload">
						<p>
							<LoadingIcon />
							Bezahlung wird ausgeführt…
						</p>
					</aside>
					: null}

			</article>

			{props.isMobile ?
				//<Plx parallaxData={parallaxData.nextBtn} className="next">
				<button
					className="light"
					onClick={() => history.goBack()}
				>
					zurück
					</button>
				//</Plx>
				: null}
		</section>
	)
}

export default CartView


const LoadingIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		style={{
			margin: 'auto',
			backgroundColor: 'var(--bg)',
			display: 'block',
			shapeRendering: 'auto',
			backgroundPosition: 'initial initial',
			backgroundRepeat: 'initial initial',
		}}
		width="200px"
		height="200px"
		viewBox="0 0 100 100"
		preserveAspectRatio="xMidYMid"
	>
		<circle
			cx="50"
			cy="50"
			r="0"
			fill="none"
			stroke-width="2"
			//stroke="var(--primary)"
			style={{ stroke: 'var(--primary)' }}
		>
			<animate
				attributeName="r"
				repeatCount="indefinite"
				dur="1s"
				values="0;40"
				keyTimes="0;1"
				keySplines="0 0.2 0.8 1"
				calcMode="spline"
				begin="-0.5s"
			/>
			<animate
				attributeName="opacity"
				repeatCount="indefinite"
				dur="1s"
				values="1;0"
				keyTimes="0;1"
				keySplines="0.2 0 0.8 1"
				calcMode="spline"
				begin="-0.5s"
			/>
		</circle>
		<circle
			cx="50"
			cy="50"
			r="0"
			fill="none"
			stroke-width="2"
			//stroke="var(--light)"
			style={{ stroke: 'var(--light)' }}
		>
			<animate
				attributeName="r"
				repeatCount="indefinite"
				dur="1s"
				values="0;40"
				keyTimes="0;1"
				keySplines="0 0.2 0.8 1"
				calcMode="spline"
			/>
			<animate
				attributeName="opacity"
				repeatCount="indefinite"
				dur="1s"
				values="1;0"
				keyTimes="0;1"
				keySplines="0.2 0 0.8 1"
				calcMode="spline"
			/>
		</circle>
	</svg>
)