import React, { useState } from 'react'
import { setCookie } from '../util/cookies'
import viewData from '../util/viewData'

const AllowCookies = props => {
	const [dsbOpen, setDsbOpen] = useState(false)
	return (
		<aside id="allow-cookies">
			<p>Diese Website benutzt Cookies und JavaScript.</p>
			<button onClick={e => {
				setCookie('allowCookies', true, 180)
				props.setAllowCookies(true)
			}}>akzeptieren</button>
			<button className="light" onClick={e => setDsbOpen(true)}>Datenschutz&shy;bestimmungen</button>

			<footer className={dsbOpen? 'open' :null}>
				<button onClick={e => setDsbOpen(false)}>schließen</button>
				{viewData.filter(v => v.name === 'legal')[0].content.map(row => (row))}
				<button onClick={e => setDsbOpen(false)}>schließen</button>
			</footer>
		</aside>
	)
}

export default AllowCookies