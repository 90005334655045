import React from 'react'

const ShippingContext = React.createContext({
	firstName: '',
	setFirstName: () => {},
	lastName: '',
	setLastName: () => {},
	fullName: '',
	setFullName: () => {},
	addressLine1: '',
	setAddressLine1: () => {},
	city: '',
	setCity: () => {},
	state: '',
	setState: () => {},
	postalCode: '',
	setPostalCode: () => {},
	countryCode: '',
	setCountryCode: () => {},

	invoiceBody: {},
	setInvoiceBody: () => {},
	setDiscount: () => {},
	invoiceNumber: 0,
	setInvoiceNumber: () => {},
	invoiceID: '',
	setInvoiceID: () => {},
	email: '',
	setEmail: () => {},
})

export default ShippingContext