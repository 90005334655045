import React, { useContext, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { apiRequest } from '../util/apiRequest'
import CartContext from '../context/CartContext'
import ShippingContext from '../context/ShippingContext'
import viewData from '../util/viewData'
import { useHistory } from 'react-router'
//import createShipment from '../util/dhlRequest'

const PaypalBtns = props => {
	const {
		paidFor, setPaidFor,
		payAbort, setPayAbort,
		bottles,
		boxes,
	} = useContext(CartContext)
	const {
		firstName,
		lastName,
		addressLine1,
		state,
		city,
		postalCode,
		countryCode,
		setInvoiceBody,
		setInvoiceID,
		//fullName,
		email
	} = useContext(ShippingContext)
	const {
		price,
		//discount,
		total,
		tax_total,
		netto,
		//stock,
	} = props.cartData()

	const PayBtns = window.paypal.Buttons.driver('react', { React, ReactDOM })

	const history = useHistory()

	const [, setStreet] = useState('')
	const [, setNumber] = useState('')

	useEffect(() => {
		if (!addressLine1 || !addressLine1.match(/([^\d]*)\s\d+/i) || addressLine1.match(/([^\d]*)\s\d+/i).length < 2) return () => { }

		setStreet(addressLine1.match(/([^\d]*)\s\d+/i)[1])
		setNumber(addressLine1.match(/[^\d]*\s([\d]+)/i)[1])
	}, [addressLine1])


	const bottle = {
		name: viewData.filter(v => v.name === 'products/bottle')[0].title,
		description: viewData.filter(v => v.name === 'products/bottle')[0].subTitle,
		unit_amount: {
			currency_code: 'EUR',
			value: (price.bottle - price.tax.bottle).toFixed(2),
		},
		tax: {
			currency_code: 'EUR',
			value: (price.tax.bottle).toFixed(2),
		},
		sku: 'id1',
		quantity: bottles
	}
	const box = {
		name: viewData.filter(v => v.name === 'products/box')[0].title,
		description: viewData.filter(v => v.name === 'products/box')[0].subTitle,
		unit_amount: {
			currency_code: 'EUR',
			value: (price.box - price.tax.box).toFixed(2),
		},
		tax: {
			currency_code: 'EUR',
			value: (price.tax.box).toFixed(2),
		},
		sku: 'id2',
		quantity: boxes
	}

	const items = () => {
		if (!bottles && !boxes) return null
		if (bottles && boxes) return [bottle, box]
		if (boxes) return [box]
		if (bottles) return [bottle]
		/* if (bottles && boxes) return [{
			bottle
		}, {
			box
		}] */
		if (boxes) return [box]
		if (bottles) return [bottle]
	}

	const createOrder = (data, actions) => {
		const purchase_units = [{
			items: items(),
			amount: {
				currency_code: 'EUR',
				value: bottles + boxes > 1 ? total.toFixed(2) : (total + 1).toFixed(2),
				breakdown: {
					item_total: {
						currency_code: 'EUR',
						value: netto.toFixed(2),
					},
					tax_total: {
						currency_code: 'EUR',
						value: tax_total.toFixed(2),
					},
					shipping: {
						currency_code: 'EUR',
						value: bottles + boxes > 1 ? '0.00' : '1.00'
					}
				}
			},
			shipping: {
				//name: fullName,
				address: {
					address_line_1: addressLine1,
					address_line_2: '',
					admin_area_1: state,
					admin_area_2: city,
					postal_code: postalCode,
					country_code: countryCode
				}
			}
			//custom_id: 'LV-BV-05-1',
			//invoice_id: 'LV-BV-05-1',]
		}]
		const payer = {
			name: {
				given_name: firstName,
				surname: lastName,
				//full_name: fullName
			},
			email_adress: 'customer@shop.de',
			address: {
				address_line_1: addressLine1,
				address_line_2: '',
				admin_area_1: state,
				admin_area_2: city,
				postal_code: postalCode,
				country_code: countryCode,
			},
			phone: {
				phone_type: "MOBILE",
				phone_number: {
					national_number: "14082508100"
				}
			}
		}
		//console.log(purchase_units[0])

		return actions.order.create({
			purchase_units,
			payer
		})
	}


	//if (!bottles && !boxes) return <React.Fragment />

	return (<>
		{paidFor | payAbort ? (
			<h3 className="fast animated fadeInUp">{!payAbort ? 'Die Zahlung war erfolgreich.' : 'Die Zahlung wurde abgebrochen.'}</h3>
		) : (
				<PayBtns
					{...props}
					shipping={1}// disable custom shipping address
					currency='EUR'
					createOrder={createOrder}
					onClick={() => props.setIsPaying(true)}
					onApprove={(data, actions) => {// CART VALID
						actions.order.capture()
							.then(details => {
								setPaidFor(true)
								if (details.error)
									console.log(details.error)
								apiRequest({// PAYPAL REQUEST
									data,
									cb: ib => {
										const php = new FormData()
										const body = {
											response: ib,
											shopData: {
												firstName,
												lastName,
												addressLine1,
												state,
												city,
												postalCode,
												countryCode,
												email,
												count: {
													bottles, boxes
												},
												...props.shipping
											},
										}
										php.append('json', JSON.stringify(body))

									/*	createShipment({// DHL VERSANDSCHEIN
											action: 'create',
											//date,
											//weight,
											//dimensions,
											//email,
											fullName,
											street,
											number,
											zip: postalCode,
											city,
											state,
											cb: (sta, msg, label, num) => {
												//sta === 'ok' ? console.log(label) : null
												php.append('label', label)
									*/			fetch(process.env.REACT_APP_INV_URL, {// LVIVSKA SHOP RECHNUNG
													method: 'POST',
													body: php
												})
													.then(res => res.json())
													.then(res => {



														setInvoiceBody(body)
														setInvoiceID(res.invoice_id)
														history.push('/invoice')
													})
													.catch(err => {
														console.error(err)
														setPayAbort(true)
													})
											}
									/*	})
									}	*/
								})
							})
							.catch(err => {
								console.error(err)
								setPayAbort(true)
							})
					}}
					onCancel={() => setPayAbort(true)}
					onError={err => {
						console.error(err)
						setPayAbort(true)
					}}
				/*	onShippingChange={(data, actions) => {
						//console.log(data)
						//console.log(actions)
						if (data.shipping_address.country_code !== 'DE') {
							return actions.reject();
						}
						return actions.resolve();
					}}	*/
				/>
			)}
	</>)

}

export default PaypalBtns